import { styled } from "@mui/joy";
import Link from "next/link";

import { ButtonProps } from "./Button";
import { Icon, IconProps } from "../../../ui-atoms/components/Icons/Icon";

/** The different button sizes */
export enum ButtonIconSizes {
	SIZE24 = "SIZE24",
	SIZE32 = "SIZE32",
	SIZE40 = "SIZE40",
}

/** Button icon props */
export interface ButtonIconProps
	extends Pick<
		ButtonProps & IconProps,
		"className" | "disabled" | "href" | "name" | "onClick"
	> {
	/** Set to `true` if you want no background and no padding (default: false) */
	noBackground?: boolean;
	/** The button size (default: SIZE40) */
	size?: ButtonIconSizes;
}

/**
 * Button icon component
 *
 * @param props Props to generate the component
 */
export const ButtonIcon = (props: ButtonIconProps): JSX.Element => {
	const { href, noBackground = false, ...btnProps } = props;

	if (href) {
		return (
			<Link href={href} passHref>
				<ButtonIcon {...btnProps} />
			</Link>
		);
	}

	const { name, size = ButtonIconSizes.SIZE40, ...rest } = btnProps;

	return (
		<ButtonIconStyled noBackground={noBackground} size={size} {...rest}>
			<Icon name={name} size={sizeStyles[size].iconSize} />
		</ButtonIconStyled>
	);
};

interface SizeStylesProps {
	buttonSize: string;
	iconSize: number;
}
/**
 * Why is SIZE24 actually 16px ?
 * Because that's the way the variants are named and sized on Figma
 */
const sizeStyles = {
	[ButtonIconSizes.SIZE24]: {
		buttonSize: "16px",
		iconSize: 10,
	},
	[ButtonIconSizes.SIZE32]: {
		buttonSize: "24px",
		iconSize: 16,
	},
	[ButtonIconSizes.SIZE40]: {
		buttonSize: "32px",
		iconSize: 24,
	},
} as const satisfies Record<ButtonIconSizes, SizeStylesProps>;

const ButtonIconStyled = styled("button")<{
	disabled?: boolean;
	noBackground: boolean;
	size: ButtonIconSizes;
}>`
	align-items: center;
	background: ${({ noBackground, theme }) =>
		noBackground ? "none" : theme.palette.grey[30]};
	border-radius: 50%;
	color: ${({ disabled, theme }) =>
		disabled ? theme.palette.grey[45] : theme.palette.blue.primary};
	cursor: pointer;
	display: flex;
	height: ${({ noBackground, size }) =>
		noBackground ? `auto` : sizeStyles[size].buttonSize};
	justify-content: center;
	padding: 0;
	width: ${({ noBackground, size }) =>
		noBackground ? "auto" : sizeStyles[size].buttonSize};

	&:hover {
		background: ${({ noBackground, theme }) =>
			noBackground ? "none" : theme.palette.grey[45]};
	}

	${({ disabled }) => disabled && "cursor: not-allowed;"}
`;
