import * as z from "zod";

import { sourceAmountDtoSchema } from "../../../source-amount";
import { creditClosureModelSchema } from "../credit-closure.model";

/** Validation schema for {@link CreditClosureDto} */
export const creditClosureDtoSchema = z.object({
	...creditClosureModelSchema.shape,
	funds: z.array(z.lazy(() => sourceAmountDtoSchema)),
});
export type CreditClosureDto = z.infer<typeof creditClosureDtoSchema>;
