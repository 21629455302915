import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { entryModelSchema } from "../entry.model";

/** Validation schema for {@link Customer} */
export const confessionSchema = z.enum([
	"Catholique romaine",
	"Catholique chrétienne",
	"juive",
	"réformée",
	"sans confession",
	"autre",
]);
export type Confession = z.infer<typeof confessionSchema>;

/** Zod Shape for {@link personalModelSchema} */
export const personalModelRawShape = {
	confession: confessionSchema.nullable(),
	customer: EntryCommon.customerSingleSchema.describe(
		"The personal information of customer",
	),
	retirement: dateCappedSchema({ yearMax: 2150 }).describe(
		"The retirement date",
	),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link PersonalModel} */
export const personalModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(personalModelRawShape),
		{
			...personalModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});

export type PersonalModel = z.infer<typeof personalModelSchema>;
