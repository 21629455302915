import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	creditInterestModelRawShape,
	creditInterestModelSchema,
} from "../credit.interest.model";

/** Validation schema for {@link CreditInterestDto} */
export const creditInterestDtoSchema = creditInterestModelSchema;
export type CreditInterestDto = z.infer<typeof creditInterestDtoSchema>;

/** Validation schema for {@link CreditInterestCreateDto} */
export const creditInterestCreateDtoSchema = z.object(
	creditInterestModelRawShape,
);
/** Dto to create a `credit */
export type CreditInterestCreateDto = z.infer<
	typeof creditInterestCreateDtoSchema
>;

/** Validation schema for {@link CreditInterestQueryDto} */
export const creditInterestQueryDtoSchema = createQueryObjectSchema(
	creditInterestDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `creditInterest` */
export type CreditInterestQueryDto = z.infer<
	typeof creditInterestQueryDtoSchema
>;

/** Validation schema for {@link CreditInterestQueryResultsDto} */
export const creditInterestQueryResultsDtoSchema = createQueryResultsSchema(
	creditInterestDtoSchema,
);
/** Results for `credits` from a query */
export type CreditInterestQueryResultsDto = z.infer<
	typeof creditInterestQueryResultsDtoSchema
>;

/** Validation schema for {@link CreditInterestUpdateDto} */
export const creditInterestUpdateDtoSchema =
	creditInterestCreateDtoSchema.partial();
/** Dto to update a `credit */
export type CreditInterestUpdateDto = z.infer<
	typeof creditInterestUpdateDtoSchema
>;
