import { Entry } from "~/common";

import { fetchRequest } from "../../../utils/utils";
import { getRequestMethodFromHttpMethod } from "../../utils";

const { routes } =
	Entry.ENTRY_HTTP_CONFIG.children.transferables.children.closures;

/** Http client for `Entry/Transferable/Closure` features */
export const TransferableClosureHttpClient: Entry.TransferableClosureHttp = {
	create: (params, body) =>
		fetchRequest({
			body,
			endpoint: routes.create.path(params),
			method: getRequestMethodFromHttpMethod(routes.create.method),
		}),
	delete: params =>
		fetchRequest({
			endpoint: routes.delete.path(params),
			method: getRequestMethodFromHttpMethod(routes.delete.method),
		}),
	update: (params, body) =>
		fetchRequest({
			body,
			endpoint: routes.update.path(params),
			method: getRequestMethodFromHttpMethod(routes.update.method),
		}),
};
