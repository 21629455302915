import * as z from "zod";

/** Constraints applied to {@link positiveAmountSchema} (for SQL checks for example) */
export const AMOUNT_CONSTRAINTS = { gte: 0 };
/** Schema for an amount value */
export const positiveAmountSchema = z.number().gte(AMOUNT_CONSTRAINTS.gte);

export const amountSchema = z.number();

/** Constraints applied to {@link boundedPercentageSchema} (for SQL checks for example) */
export const PERCENTAGE_CONSTRAINTS = { gte: 0, lte: 100 };

/** Schema for an (almost ?) unbounded percentage value */
export const percentageSchema = z.number();
/** Schema for a percentage value */
export const boundedPercentageSchema = percentageSchema
	.gte(PERCENTAGE_CONSTRAINTS.gte)
	.lte(PERCENTAGE_CONSTRAINTS.lte);
