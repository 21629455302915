import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	stockMarketModelRawShape,
	stockMarketModelSchema,
} from "../stock.market.model";

/** Validation schema for {@link StockMarketDto} */
export const stockMarketDtoSchema = stockMarketModelSchema;
export type StockMarketDto = z.infer<typeof stockMarketDtoSchema>;

/** Validation schema for {@link StockMarketCreateDto} */
export const stockMarketCreateDtoSchema = z.object(stockMarketModelRawShape);
/** Dto to create a `stock */
export type StockMarketCreateDto = z.infer<typeof stockMarketCreateDtoSchema>;

/** Validation schema for {@link StockMarketQueryDto} */
export const stockMarketQueryDtoSchema = createQueryObjectSchema(
	stockMarketDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `stockMarket` */
export type StockMarketQueryDto = z.infer<typeof stockMarketQueryDtoSchema>;

/** Validation schema for {@link StockMarketQueryResultsDto} */
export const stockMarketQueryResultsDtoSchema =
	createQueryResultsSchema(stockMarketDtoSchema);
/** Results for `stocks` from a query */
export type StockMarketQueryResultsDto = z.infer<
	typeof stockMarketQueryResultsDtoSchema
>;

/** Validation schema for {@link StockMarketUpdateDto} */
export const stockMarketUpdateDtoSchema = stockMarketCreateDtoSchema.partial();
/** Dto to update a `stock */
export type StockMarketUpdateDto = z.infer<typeof stockMarketUpdateDtoSchema>;
