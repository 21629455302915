import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../source-amount/source-amount.actions";
import { creditClosureModelSchema } from "../credit-closure.model";

/** Validation schema for {@link CreditClosureCreateDto} */
export const creditClosureCreateDtoSchema = z.object({
	date: creditClosureModelSchema.shape.date,
	funds: sourceAmountActionsSchema,
});
export type CreditClosureCreateDto = z.infer<
	typeof creditClosureCreateDtoSchema
>;

/** Validation schema for {@link CreditClosureUpdateDto} */
export const creditClosureUpdateDtoSchema =
	creditClosureCreateDtoSchema.partial();
export type CreditClosureUpdateDto = z.infer<
	typeof creditClosureUpdateDtoSchema
>;
