import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { loanModelSchema } from "../loan.model";

export const typeOfLoanInterestSchema = z.enum([
	"FIXED_INTEREST",
	"VARIABLE_INTEREST",
]);
export type TypeOfLoanInterest = z.infer<typeof typeOfLoanInterestSchema>;
/** Zod Shape for {@link loanInterestModelSchema} */
export const loanInterestModelRawShape = {
	// TODO: Templating: Add the specific field of the model
	date: dateCappedSchema({}),
	percent: z.number().gte(0).lte(100),
	type: typeOfLoanInterestSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link LoanModel} */
export const loanInterestModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(loanInterestModelRawShape),
		{
			...loanInterestModelRawShape,
			fkLoan: loanModelSchema.shape._id,
		},
	),
});

export type LoanInterestModel = z.infer<typeof loanInterestModelSchema>;
