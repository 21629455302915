import styled from "@emotion/styled";
import * as joy from "@mui/joy";

import { Icon } from "../../../ui-atoms/components/Icons/Icon";
import { textBody } from "../../../ui-layout/styles/textStyles";
import { isReadonlyArray } from "../input.date-iso.utils";
import { getSelectListBoxStyle } from "./SelectListBox";

/**
 * Override of {@link joy.Select} with default style and some properties
 * Prefer `AutoComplete` when possible
 */
export const Select: typeof joy.Select = (
	props: joy.SelectProps<Record<string, never>, false, "button">,
) => {
	const { sx = [], ...selectProps } = props;

	// The real type of `props` is not important; the component will be correctly typed
	return (
		<SelectStyled
			data-testid="ui/select"
			indicator={<Icon name="keyboardArrowDown" />}
			slotProps={{
				listbox: {
					//https://github.com/mui/material-ui/issues/35795
					disablePortal: true,
					sx: getSelectListBoxStyle,
				},
			}}
			{...selectProps}
			sx={[
				{
					// https://mui.com/joy-ui/react-select/#indicator
					[`& .${joy.selectClasses.indicator}`]: {
						transition: "0.2s",
						[`&.${joy.selectClasses.expanded}`]: {
							transform: "rotateX(-180deg)",
						},
					},
				},
				...(isReadonlyArray(sx) ? sx : [sx]),
			]}
		/>
	);
};
export type { SelectProps } from "@mui/joy";

/** Default style for the generic {@link Select} */
const SelectStyled = styled(joy.Select)`
	${textBody};
` as typeof joy.Select;
