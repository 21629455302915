import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { creditModelSchema } from "../credit.model";

export const typeOfCreditInterestSchema = z.enum([
	"FIXED_INTEREST",
	"VARIABLE_INTEREST",
]);
export type TypeOfCreditInterest = z.infer<typeof typeOfCreditInterestSchema>;
/** Zod Shape for {@link creditInterestModelSchema} */
export const creditInterestModelRawShape = {
	// TODO: Templating: Add the specific field of the model
	date: dateCappedSchema({}),
	percent: z.number().gte(0).lte(100),
	type: typeOfCreditInterestSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link CreditModel} */
export const creditInterestModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(creditInterestModelRawShape),
		{
			...creditInterestModelRawShape,
			fkCredit: creditModelSchema.shape._id,
		},
	),
});

export type CreditInterestModel = z.infer<typeof creditInterestModelSchema>;
