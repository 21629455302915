import * as z from "zod";

import { sourceAmountDtoSchema } from "../../../source-amount";
import { stockClosureModelSchema } from "../stock-closure.model";

/** Validation schema for {@link StockClosureDto} */
export const stockClosureDtoSchema = z.object({
	...stockClosureModelSchema.shape,
	funds: z.array(z.lazy(() => sourceAmountDtoSchema)),
});
export type StockClosureDto = z.infer<typeof stockClosureDtoSchema>;
