import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../source-amount/source-amount.actions";
import { loanClosureModelSchema } from "../loan-closure.model";

/** Validation schema for {@link LoanClosureCreateDto} */
export const loanClosureCreateDtoSchema = z.object({
	date: loanClosureModelSchema.shape.date,
	funds: sourceAmountActionsSchema,
});
export type LoanClosureCreateDto = z.infer<typeof loanClosureCreateDtoSchema>;

/** Validation schema for {@link LoanClosureUpdateDto} */
export const loanClosureUpdateDtoSchema = loanClosureCreateDtoSchema.partial();
export type LoanClosureUpdateDto = z.infer<typeof loanClosureUpdateDtoSchema>;
