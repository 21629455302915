import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaWithShapeFieldsV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { sourceDtoSchema, sourceSchema } from "../source/source.model";

/** Zod Shape for {@link sourceAmountModelSchema} */
export const sourceAmountModelRawShape = {
	amount: EntryCommon.amountSchema.describe(""),
	source: z.lazy(() => sourceSchema.describe("Origin of the amount")),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link SourceAmountModel} */
export const sourceAmountModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaWithShapeFieldsV0(sourceAmountModelRawShape, [
			// `source`/`group` can not be modified once created
			"amount",
		]),
		sourceAmountModelRawShape,
	),
});
/**
 * The "simple" 'source-amount' relates a source with an absolute amount (except for 'account-closure').
 * This is attached to 'movement-groups' such as account/loan-evolution/...
 *
 * Note: the field `amount` use percentage with `account-closure`
 *
 * In the end implement, this uses a movement-group
 * > More description in the "full" version of this model
 */
export type SourceAmountModel = z.infer<typeof sourceAmountModelSchema>;

/** Validation schema for {@link SourceAmountDto} */
export const sourceAmountDtoSchema = z.object({
	...sourceAmountModelSchema.shape,
	source: z.lazy(() => sourceDtoSchema.describe("Origin of the amount")),
} satisfies Record<keyof SourceAmountModel, z.ZodTypeAny>);
/** Extension of {@link SourceAmountModel} with the DTO version of the sources */
export type SourceAmountDto = z.infer<typeof sourceAmountModelSchema>;
