import { HttpRoute } from "@nna/core";

import {
	InterruptionCreateDto,
	InterruptionDto,
	InterruptionQueryDto,
	InterruptionQueryResultsDto,
	InterruptionUpdateDto,
} from "./dtos";
import { ChildHttpConfig } from "../child.http";

/**
 * Create routes definitions for `child/interruption` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createInterruptionHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "interruptionId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: InterruptionQueryDto,
					) => Promise<InterruptionQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<InterruptionDto>>(),

			create: base.post<
				(body: InterruptionCreateDto) => Promise<InterruptionDto>
			>(),
			delete: baseById.delete<() => Promise<InterruptionDto>>(),
			update: baseById.patch<
				(body: InterruptionUpdateDto) => Promise<InterruptionDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Child/Interruption` features and children */
type InterruptionHttpConfig = ChildHttpConfig["children"]["interruptions"];
/** HTTP specification for the `Entry/Child` features */
export type InterruptionHttp = HttpRoute.Handlers<
	InterruptionHttpConfig["routes"]
>;
