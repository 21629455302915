import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { entryModelSchema } from "../entry.model";
/** Zod Shape for {@link stockModelSchema} */
export const stockModelRawShape = {
	beneficiary: EntryCommon.customerSingleSchema,

	description: z.string(),
	stockNb: z.number().nullable(),

	depositoryCompany: z.string().nullable(),

	nbOfStock: z.number(),
	totalNbOfStock: z.number().nullable(),

	qualifiedParticipation: z.boolean(),

	dividendPaymentDate: dateCappedSchema({}).nullable(),

	marketValue: z.number(),
	fiscalValue: z.number(),

	purchaseDate: dateCappedSchema({}),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link StockModel} */
export const stockModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(stockModelRawShape),
		{
			...stockModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});

export type StockModel = z.infer<typeof stockModelSchema>;
