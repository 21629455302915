import * as joy from "@mui/joy";
import * as mui from "@mui/system";

/**
 * SX function that returns the styling for the SelectListbox component
 *
 * @param theme the Joy theme
 * @returns The style for the listbox slotProps
 */
export function getSelectListBoxStyle(theme: joy.Theme) {
	// Because there is no easier way to do it :sad_face:
	return {
		backgroundColor: theme.palette.white.primary,
		border: "none",
		borderRadius: "8px",
		boxShadow:
			"0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 44px 0px rgba(33, 43, 52, 0.14)",
		padding: 0,
		pointer: "cursor",
	} as const satisfies mui.StandardCSSProperties & { pointer: string };
}
