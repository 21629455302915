import * as z from "zod";

import { createClosureModelSchema } from "../../common";
import { stockModelSchema } from "../stock.model";

/** Validation schema for {@link StockClosureModel} */
export const stockClosureModelSchema = createClosureModelSchema(
	{},
	{ fkStock: stockModelSchema.shape._id },
);
export type StockClosureModel = z.infer<typeof stockClosureModelSchema>;
