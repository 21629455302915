import { createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { accountDtoSchema } from "./account.dto";

/** Validation schema for {@link AccountQueryResultsDto} */
export const accountQueryResultsDtoSchema =
	createQueryResultsSchema(accountDtoSchema);
/** Results for `accounts` from a query */
export type AccountQueryResultsDto = z.infer<
	typeof accountQueryResultsDtoSchema
>;
