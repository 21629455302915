import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { stockModelSchema } from "../stock.model";

export const typeOfStockMarketSchema = z.enum([
	"FIXED_INTEREST",
	"VARIABLE_INTEREST",
]);
export type TypeOfStockMarket = z.infer<typeof typeOfStockMarketSchema>;
/** Zod Shape for {@link stockMarketModelSchema} */
export const stockMarketModelRawShape = {
	// TODO: Templating: Add the specific field of the model
	date: dateCappedSchema({}),
	percent: z.number().gte(0).lte(100),
	type: typeOfStockMarketSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link StockModel} */
export const stockMarketModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(stockMarketModelRawShape),
		{
			...stockMarketModelRawShape,
			fkStock: stockModelSchema.shape._id,
		},
	),
});

export type StockMarketModel = z.infer<typeof stockMarketModelSchema>;
