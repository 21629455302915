import * as z from "zod";

import { sourceAmountDtoSchema } from "../../../source-amount";
import { loanClosureModelSchema } from "../loan-closure.model";

/** Validation schema for {@link LoanClosureDto} */
export const loanClosureDtoSchema = z.object({
	...loanClosureModelSchema.shape,
	funds: z.array(z.lazy(() => sourceAmountDtoSchema)),
});
export type LoanClosureDto = z.infer<typeof loanClosureDtoSchema>;
