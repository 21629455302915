import { HttpRoute } from "@nna/core";

import { CreateLoanEvolutionHttpDefinitions } from "./evolution";
import { CreateLoanInterestHttpDefinitions } from "./interest";
import { type EntryHttpConfig } from "../entry.http";
import { CreateLoanClosureHttpDefinitions } from "./closure";
import type {
	LoanCreateDto,
	LoanDto,
	LoanQueryDto,
	LoanQueryResultsDto,
	LoanUpdateDto,
} from "./dtos";

/**
 * Create routes definitions for `Loan` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createLoanHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "loanId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			/** Note: even if, for now, there is only one closure by loan, the plural is ready for V2 */
			closures: CreateLoanClosureHttpDefinitions(
				baseById.addSegment("closures"),
			),
			evolutions: CreateLoanEvolutionHttpDefinitions(
				baseById.addSegment("evolutions"),
			),
			interests: CreateLoanInterestHttpDefinitions(
				baseById.addSegment("interests"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(query: LoanQueryDto) => Promise<LoanQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<LoanDto>>(),

			create: base.post<(body: LoanCreateDto) => Promise<LoanDto>>(),
			delete: baseById.delete<() => Promise<LoanDto>>(),
			update: baseById.patch<(body: LoanUpdateDto) => Promise<LoanDto>>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Loan` features and children */
export type LoanHttpConfig = EntryHttpConfig["children"]["loans"];
/** HTTP specification for the `Entry/Loan` features */
export type LoanHttp = HttpRoute.Handlers<LoanHttpConfig["routes"]>;
