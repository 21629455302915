import * as z from "zod";

import { accountModelSchema } from "../../account/account.model";
import { accountBaseDtoSchema } from "../../account/dtos/account.dto";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "account";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkAccount: accountModelSchema.shape._id,
});
/** Movement source for an {@link accountBaseDtoSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	account: z.lazy(() => accountBaseDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
