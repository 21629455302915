import * as dateFns from "date-fns";

/**
 * Get the initial date from an year
 *
 * @param year to get the date from
 * @returns the first day of the year
 */
export function fromYear(year: number) {
	return dateFns.startOfYear(dateFns.setYear(0, year));
}
