import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { createMultilineSchema } from "../../common/multiline";
import { creditClosureDtoSchema } from "../closure/dtos";
import { creditModelRawShape, creditModelSchema } from "../credit.model";
import { creditEvolutionDtoSchema } from "../evolution/dtos";
import {
	creditInterestCreateDtoSchema,
	creditInterestDtoSchema,
	creditInterestUpdateDtoSchema,
} from "../interest";

/** Validation schema for {@link CreditDto} */
export const creditDtoSchema = z.object({
	...creditModelSchema.shape,
	closure: z
		.lazy(() => creditClosureDtoSchema)
		.nullable()
		.describe("The closure of this credit.<br>`null` when there is none"),
	evolutions: z.array(z.lazy(() => creditEvolutionDtoSchema)),
	interests: z.array(creditInterestDtoSchema),
});
export type CreditDto = z.infer<typeof creditDtoSchema>;

/** Validation schema for {@link CreditCreateDto} */
export const creditCreateDtoSchema = z
	.object({
		...creditModelRawShape,
		interests: createMultilineSchema(
			creditInterestCreateDtoSchema,
			creditInterestUpdateDtoSchema,
		),
	})
	.strict();
/** Dto to create a `credit */
export type CreditCreateDto = z.infer<typeof creditCreateDtoSchema>;

/**
 * Validation schema for {@link CreditQueryDto}
 * Note that we use 'creditModelSchema' here, because using the 'creditDtoSchema' with interests
 * is causing a ts(2589) issue in the backend payload file.
 */
export const creditQueryDtoSchema = createQueryObjectSchema(creditModelSchema, {
	coerce: true,
	strict: true,
}).strict();

/** DTO used to filter `credit` */
export type CreditQueryDto = z.infer<typeof creditQueryDtoSchema>;

/** Validation schema for {@link CreditQueryResultsDto} */
export const creditQueryResultsDtoSchema =
	createQueryResultsSchema(creditDtoSchema);
/** Results for `credits` from a query */
export type CreditQueryResultsDto = z.infer<typeof creditQueryResultsDtoSchema>;

/** Validation schema for {@link CreditUpdateDto} */
export const creditUpdateDtoSchema = creditCreateDtoSchema.partial();
/** Dto to update a `credit */
export type CreditUpdateDto = z.infer<typeof creditUpdateDtoSchema>;
