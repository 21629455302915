import { HttpRoute } from "@nna/core";

import type {
	StockTransactionCreateDto,
	StockTransactionDto,
	StockTransactionQueryDto,
	StockTransactionQueryResultsDto,
	StockTransactionUpdateDto,
} from "./dtos";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `StockTransaction` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateStockTransactionHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "transactionId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: StockTransactionQueryDto,
					) => Promise<StockTransactionQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<StockTransactionDto>>(),

			create: base.post<
				(
					body: StockTransactionCreateDto,
				) => Promise<StockTransactionDto>
			>(),
			delete: baseById.delete<() => Promise<StockTransactionDto>>(),
			update: baseById.patch<
				(
					body: StockTransactionUpdateDto,
				) => Promise<StockTransactionDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/StockTransaction` features and children */
export type StockTransactionHttpConfig =
	EntryHttpConfig["children"]["stocks"]["children"]["transactions"];
/** HTTP specification for the `Entry/StockTransaction` features */
export type StockTransactionHttp = HttpRoute.Handlers<
	StockTransactionHttpConfig["routes"]
>;
