import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { createMultilineSchema } from "../../common/multiline";
import { loanClosureDtoSchema } from "../closure/dtos";
import { loanEvolutionDtoSchema } from "../evolution/dtos";
import {
	loanInterestCreateDtoSchema,
	loanInterestDtoSchema,
	loanInterestUpdateDtoSchema,
} from "../interest";
import { loanModelRawShape, loanModelSchema } from "../loan.model";

/** Validation schema for {@link LoanDto} */
export const loanDtoSchema = z.object({
	...loanModelSchema.shape,
	closure: z
		.lazy(() => loanClosureDtoSchema)
		.nullable()
		.describe("The closure of this loan.<br>`null` when there is none"),
	evolutions: z.array(z.lazy(() => loanEvolutionDtoSchema)),
	interests: z.array(loanInterestDtoSchema),
});
export type LoanDto = z.infer<typeof loanDtoSchema>;

/** Validation schema for {@link LoanCreateDto} */
export const loanCreateDtoSchema = z
	.object({
		...loanModelRawShape,
		interests: createMultilineSchema(
			loanInterestCreateDtoSchema,
			loanInterestUpdateDtoSchema,
		),
	})
	.strict();
/** Dto to create a `loan */
export type LoanCreateDto = z.infer<typeof loanCreateDtoSchema>;

/**
 * Validation schema for {@link LoanQueryDto}
 * Note that we use 'loanModelSchema' here, because using the 'loanDtoSchema' with interests
 * is causing a ts(2589) issue in the backend payload file.
 */
export const loanQueryDtoSchema = createQueryObjectSchema(loanModelSchema, {
	coerce: true,
	strict: true,
}).strict();

/** DTO used to filter `loan` */
export type LoanQueryDto = z.infer<typeof loanQueryDtoSchema>;

/** Validation schema for {@link LoanQueryResultsDto} */
export const loanQueryResultsDtoSchema =
	createQueryResultsSchema(loanDtoSchema);
/** Results for `loans` from a query */
export type LoanQueryResultsDto = z.infer<typeof loanQueryResultsDtoSchema>;

/** Validation schema for {@link LoanUpdateDto} */
export const loanUpdateDtoSchema = loanCreateDtoSchema.partial();
/** Dto to update a `loan */
export type LoanUpdateDto = z.infer<typeof loanUpdateDtoSchema>;
