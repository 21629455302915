import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { entryModelSchema } from "../entry.model";

/** Zod Shape for {@link transferableModelSchema} */
export const transferableModelRawShape = {
	acquisitionDate: dateCappedSchema({}).describe(
		"the date when this asset was initially purchased/acquired",
	),
	description: z.string().min(2),
	entitled: EntryCommon.customerSingleSchema.describe(
		"the customer who owns this asset, in a multi-customer plan",
	),
	projection: EntryCommon.Evolution.schema.describe("Amount or Percent"),
	quantity: EntryCommon.amountSchema.describe("quantity of that asset owned"),
	value: EntryCommon.amountSchema.describe("total value of the assets"),

	// TODO (FG-47): provenance des fonds -- waiting for the generic model for accounts to exist (may be a OneToMany as well?)
	// External: mouvements (join entity)
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link TransferableModel} */
export const transferableModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(transferableModelRawShape),
		{
			...transferableModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});

/**
 * Represents a miscellaneous transferable asset ("mobilier divers" in the prototype)
 */
export type TransferableModel = z.infer<typeof transferableModelSchema>;
