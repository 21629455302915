import { z } from "zod";

/** Validation schema for {@link MultilineRowState} */
export const multilineRowStatesSchema = z.enum([
	"ADD",
	"UPDATE",
	"DELETE",
	"NONE",
]);
export type MultilineRowState = z.infer<typeof multilineRowStatesSchema>;

/**
 * Generator for multiline row schema
 *
 * @param schema the multiline row form schema
 * @returns multiline row schema
 */
export function createMultilineRowSchema<S extends z.ZodTypeAny>(schema: S) {
	return z.object({
		state: multilineRowStatesSchema,
		value: schema,
	});
}

/**
 * Generator for multiline row schema
 *
 * @param schemaCreate the multiline entity full schema (= for create)
 * @param schemaUpdate the multiline entity partial schema (= for update)
 * @returns multiline row schema
 */
export function createMultilineRowPROPERSchema<
	S1 extends z.ZodTypeAny,
	S2 extends z.ZodTypeAny,
>(schemaCreate: S1, schemaUpdate: S2) {
	return z.discriminatedUnion("state", [
		z.object({ state: z.literal("ADD"), value: schemaCreate }),

		z.object({
			_id: z.number().describe("The multiline item ID"),
			state: z.literal("UPDATE"),
			value: schemaUpdate,
		}),

		z.object({
			_id: z.number().describe("The multiline item ID"),
			state: z.literal("DELETE"),
		}),

		// Fail-safe (not required)
		z.object({ state: z.literal("NONE") }),
	]);
}

/**
 * Generator for multiline schema
 *
 * @param schemaCreate the multiline entity full schema (= for create)
 * @param schemaUpdate the multiline entity partial schema (= for update)
 * @returns multiline schema
 */
export function createMultilineSchema<
	S1 extends z.ZodTypeAny,
	S2 extends z.ZodTypeAny,
>(schemaCreate: S1, schemaUpdate: S2) {
	return z.array(createMultilineRowPROPERSchema(schemaCreate, schemaUpdate));
}

/**
 * @template C for 'ADD' action
 * @template U for 'UPDATE' action
 */
export type MultilineData<
	C extends object = object,
	U extends object = object,
> = z.infer<
	ReturnType<
		typeof createMultilineRowPROPERSchema<z.ZodType<C>, z.ZodType<U>>
	>
>;
