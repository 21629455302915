import * as z from "zod";

import { createClosureModelSchema } from "../../common";
import { loanModelSchema } from "../loan.model";

/** Validation schema for {@link LoanClosureModel} */
export const loanClosureModelSchema = createClosureModelSchema(
	{},
	{ fkLoan: loanModelSchema.shape._id },
);
export type LoanClosureModel = z.infer<typeof loanClosureModelSchema>;
