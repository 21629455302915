import * as z from "zod";

import { Options } from "./data.options";

// To not do in the `index.ts` file or
//	it will probably generate circular reference (and break tree-shaking)
const schemas = [
	Options.Empty.schema,
	Options.Quantity.schema,
] as const satisfies Array<z.ZodType<Options.BaseModel<string>>>;

/** Validation schema for {@link Model} */
export const schema = z.discriminatedUnion(Options.DISCRIMINATION_KEY, schemas);
/** Model of known Movement data */
export type Model = z.infer<typeof schema>;

/** Known type for {@link Model} */
export type Type = Model[typeof Options.DISCRIMINATION_KEY];
/** List of available types for {@link Model} */
export const TYPES: readonly Type[] = schema.options.map(
	({ shape }) => shape[Options.DISCRIMINATION_KEY].value,
);

/**
 * Extracts from the {@link Model} the sub-type of the given template
 *
 * @example
 * type DateEmpty = ModelOfType<"empty">;
 * // Is equal to
 * type DateEmpty = Options.Empty.Model;
 */
export type ModelOfType<T extends Type> = Extract<Model, Options.BaseModel<T>>;
