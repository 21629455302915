import * as z from "zod";

import { customerDtoSchema } from "../../customer/dtos/customer.dto";
import { entryModelSchema } from "../../entry";
import { userDtoSchema } from "../../user/dtos";
import { planModelSchema } from "../plan.model";

/** Validation schema for {@link PlanDto} */
export const planDtoSchema = z.object({
	...planModelSchema.shape,
	fkEntry: entryModelSchema.shape._id.describe(
		"Foreign key to the 'root-entry'",
	),

	counselor: z
		// The counselor is always returned
		// `lazy` not mandatory but to avoid circular ref
		.lazy(() => userDtoSchema)
		.describe("The counselor data of the customer"),
	customer: z.lazy(() => customerDtoSchema),
	customer2: z.lazy(() => customerDtoSchema).nullable(),
});
export type PlanDto = z.infer<typeof planDtoSchema>;
