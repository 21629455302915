import { createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { movementDtoSchema } from "./movement.dto";

/** Validation schema for {@link MovementQueryResultsDto} */
export const movementQueryResultsDtoSchema =
	createQueryResultsSchema(movementDtoSchema);
/** Results for `movements` from a query */
export type MovementQueryResultsDto = z.infer<
	typeof movementQueryResultsDtoSchema
>;
