import { HttpRoute } from "@nna/core";

import type {
	LoanClosureCreateDto,
	LoanClosureDto,
	LoanClosureUpdateDto,
} from "./dtos";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `LoanClosure` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateLoanClosureHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "closureId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			create: base.post<
				(body: LoanClosureCreateDto) => Promise<LoanClosureDto>
			>(),
			delete: baseById.delete<() => Promise<LoanClosureDto>>(),
			update: baseById.patch<
				(body: LoanClosureUpdateDto) => Promise<LoanClosureDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/LoanClosure` features and children */
export type LoanClosureHttpConfig =
	EntryHttpConfig["children"]["loans"]["children"]["closures"];
/** HTTP specification for the `Entry/LoanClosure` features */
export type LoanClosureHttp = HttpRoute.Handlers<
	LoanClosureHttpConfig["routes"]
>;
