import * as z from "zod";

/** The discrimination key for the sources */
export const DISCRIMINATION_KEY = "type";

/**
 * Base for element schemas
 *
 * @param type to set to the schema
 * @param shape to add to the schema
 * @returns base schema for the discriminations
 */
export function createSchema<
	const T extends string,
	const S extends z.ZodRawShape,
>(type: T, shape: S) {
	return z.object({
		...shape,
		[DISCRIMINATION_KEY]: z.literal(type),
	});
}

/** Base model from {@link createSchema} */
export type BaseModel<T extends string> = z.infer<
	ReturnType<typeof createSchema<T, Record<never, never>>>
>;
