import * as dateFns from "date-fns";

const AGE_OF_END_OF_STUDIES = 25;

/**
 * Compute a date of end of studies based on the birthday and the standard age of end of studies
 *
 * @param birthday the child birthday
 * @returns the supposed age of end of studies
 */
export function computeEndOfStudies(birthday: Date) {
	return dateFns.addYears(birthday, AGE_OF_END_OF_STUDIES);
}
