import * as z from "zod";
import { ZodSchema } from "zod";

export const durationTypeSchema = z.enum(["YEARS", "MONTHS"]);
export type DurationType = z.infer<typeof durationTypeSchema>;

/**
 * Represents a durations in months, that can either be expressed as xx YEARS or xx MONTHS
 */
export interface MonthsDuration {
	durationAmount: number;
	durationType: DurationType;
}

/**
 * Builds a MonthsDuration schema for a given max number of years
 */
export function monthsDurationSchema(
	minYears: number,
	maxYears: number,
): ZodSchema<MonthsDuration> {
	const maxMonths = maxYears * 12;
	const minMonths = minYears * 12;

	const monthsSchema = z.object({
		durationType: z.literal("MONTHS"),
		durationAmount: z.number().min(minMonths).max(maxMonths),
	});
	const yearsSchema = z.object({
		durationType: z.literal("YEARS"),
		durationAmount: z.number().min(minYears).max(maxYears),
	});

	return z.discriminatedUnion("durationType", [monthsSchema, yearsSchema]);
}
