import { HttpRoute } from "@nna/core";

import { type EntryHttpConfig } from "../entry.http";
import { CreateTransferableClosureHttpDefinitions } from "./closure";
import type {
	TransferableCreateDto,
	TransferableDto,
	TransferableQueryDto,
	TransferableQueryResultsDto,
	TransferableUpdateDto,
} from "./dtos";

/**
 * Create routes definitions for `Transferable` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createTransferableHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "transferableId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			closures: CreateTransferableClosureHttpDefinitions(
				baseById.addSegment("closures"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(
						query: TransferableQueryDto,
					) => Promise<TransferableQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<TransferableDto>>(),

			create: base.post<
				(body: TransferableCreateDto) => Promise<TransferableDto>
			>(),
			delete: baseById.delete<() => Promise<TransferableDto>>(),
			update: baseById.patch<
				(body: TransferableUpdateDto) => Promise<TransferableDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Transferable` features and children */
export type TransferableHttpConfig =
	EntryHttpConfig["children"]["transferables"];
/** HTTP specification for the `Entry/Transferable` features */
export type TransferableHttp = HttpRoute.Handlers<
	TransferableHttpConfig["routes"]
>;
