import { z } from "zod";

/** Default caps */
const YEAR_MIN = 1900;
const YEAR_MAX = 2100;

/** Params for {@link yearCappedSchema} and {@link dateCappedSchema} */
interface yearParams {
	/** The maximum year allowed */
	yearMax?: number;
	/** The minimum year allowed */
	yearMin?: number;
}

/**
 * Validation schema for year (as number) fields that should be
 * between generic year caps.
 */
export function yearCappedSchema({
	yearMax = YEAR_MAX,
	yearMin = YEAR_MIN,
}: yearParams) {
	return z.number().int().min(yearMin).max(yearMax);
}

/**
 * Validation schema for date fields that should be
 * between generic date caps.
 */
export function dateCappedSchema({
	yearMax = YEAR_MAX,
	yearMin = YEAR_MIN,
}: yearParams) {
	return z
		.date()
		.min(new Date(yearMin, 0, 1))
		.max(new Date(yearMax, 11, 31));
}
