const languages = ["fr"];
const [languageDefault] = languages;

const namespace = "common";

/** @type {import("next-i18next").UserConfig} */
module.exports = {
	defaultLanguage: languageDefault,
	defaultNS: namespace,
	i18n: {
		defaultLocale: languageDefault,
		locales: languages,
	},
	localePath:
		// Even if there is no "client-side" translation, this the configuration suggested by the lib
		typeof window === "undefined"
			? require("path").resolve("./public/locales")
			: "/public/locales",
	ns: [namespace],
	otherLanguages: languages,
	reloadOnPrerender:
		process.env.NODE_ENV === "development" ||
		process.env.NX_TASK_TARGET_TARGET === "serve",
	serializeConfig: false,
};
