import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { createMultilineSchema } from "../../../common/multiline";
import {
	exceptionalModelRawShape,
	exceptionalModelSchema,
} from "../exceptional.model";

/** Validation schema for {@link ExceptionalDto} */
export const exceptionalDtoSchema = exceptionalModelSchema;
export type ExceptionalDto = z.infer<typeof exceptionalDtoSchema>;

/** Validation schema for {@link ExceptionalCreateDto} */
export const exceptionalCreateDtoSchema = z
	.object(exceptionalModelRawShape)
	.strict();
/** Dto to create a `recipe */
export type ExceptionalCreateDto = z.infer<typeof exceptionalCreateDtoSchema>;

/** Validation schema for {@link ExceptionalQueryDto} */
export const exceptionalQueryDtoSchema = createQueryObjectSchema(
	exceptionalDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `exceptional` */
export type ExceptionalQueryDto = z.infer<typeof exceptionalQueryDtoSchema>;

/** Validation schema for {@link ExceptionalQueryResultsDto} */
export const exceptionalQueryResultsDtoSchema =
	createQueryResultsSchema(exceptionalDtoSchema);
/** Results for `exceptionals` from a query */
export type ExceptionalQueryResultsDto = z.infer<
	typeof exceptionalQueryResultsDtoSchema
>;

/** Validation schema for {@link ExceptionalUpdateDto} */
export const exceptionalUpdateDtoSchema = z
	.object(exceptionalModelRawShape)
	.strict()
	.partial();
/** Dto to update a `exceptional */
export type ExceptionalUpdateDto = z.infer<typeof exceptionalUpdateDtoSchema>;

/** Validation schema for {@link ExceptionalUpdateDto} */
export const exceptionalBatchDtoSchema = z
	.object({
		expenses: createMultilineSchema(
			exceptionalCreateDtoSchema,
			exceptionalUpdateDtoSchema,
		),
	})
	.strict()
	.partial();
/** Dto to update a `exceptional */
export type ExceptionalBatchDto = z.infer<typeof exceptionalBatchDtoSchema>;
