import * as z from "zod";

import { loanEvolutionDtoSchema } from "../../../loan/evolution/dtos/loan.evolution.dto";
import { loanEvolutionModelSchema } from "../../../loan/evolution/loan.evolution.model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "loan-evolution";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkLoanEvolution: loanEvolutionModelSchema.shape._id,
});
/** Movement Group representation of a {@link loanEvolutionModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	loanEvolution: z.lazy(() => loanEvolutionDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
