import * as z from "zod";

import { createClosureModelSchema } from "../../common";
import { transferableModelSchema } from "../transferable.model";

/** Validation schema for {@link TransferableClosureModel} */
export const transferableClosureModelSchema = createClosureModelSchema(
	{},
	{ fkTransferable: transferableModelSchema.shape._id },
);
export type TransferableClosureModel = z.infer<
	typeof transferableClosureModelSchema
>;
