import * as z from "zod";

import { amountSchema as baseAmountSchema, percentageSchema } from "./schemas";

/** The discrimination key for {@link Evolution} */
export const DISCRIMINATION_KEY = "type";

/** @internal */
function createSchema<T extends string, S extends z.ZodRawShape>(
	type: T,
	shape: S,
) {
	return z.object({ ...shape, [DISCRIMINATION_KEY]: z.literal(type) });
}

// TODO: amount and percent schemas must allow for nullable value (when an empty string is passed)

/** The discriminated type for {@link AmountModel} */
export const AMOUNT_TYPE = "amount";
export const createAmountSchema = (optional?: boolean) =>
	createSchema(AMOUNT_TYPE, {
		amount: optional
			? baseAmountSchema.default(0).or(z.literal("").transform(() => 0)) // FORMIK_456: To handle Formik empty string state
			: baseAmountSchema,
	});

/** Validation schema for {@link AmountModel} in REQUIRED mode */
export const amountSchema = createAmountSchema(false);
/** The {@link Model} with `amount` type in REQUIRED mode */
export type AmountModel = z.infer<typeof amountSchema>;

/** Validation schema for {@link AmountModel} in OPTIONAL mode */
export const amountOptionalSchema = createAmountSchema(true);
/** The {@link Model} with `amount` type in OPTIONAL mode */
export type AmountOptionalModel = z.infer<typeof amountOptionalSchema>;

/** The discriminated type for {@link PercentModel} */
export const PERCENT_TYPE = "percent";
export const createPercentSchema = (optional?: boolean) =>
	createSchema(PERCENT_TYPE, {
		percent: optional
			? percentageSchema.default(0).or(z.literal("").transform(() => 0)) // FORMIK_456: To handle Formik empty string state
			: percentageSchema,
	});

/** Validation schema for {@link PercentModel} in REQUIRED mode */
export const percentSchema = createPercentSchema(false);
/** The {@link Model} with `percent` type in REQUIRED mode */
export type PercentModel = z.infer<typeof percentSchema>;

/** Validation schema for {@link PercentModel} in OPTIONAL mode */
export const percentOptionalSchema = createPercentSchema(false);
/** The {@link Model} with `percent` type in REQUIRED mode */
export type PercentOptionalModel = z.infer<typeof percentSchema>;

/**
 * Function to generate a {@link Evolution} schema
 *
 * @param optional If the schema should be optional
 * @returns  The {@link Evolution} schema
 */
export const createEvolutionSchema = (optional?: boolean) =>
	z.discriminatedUnion(DISCRIMINATION_KEY, [
		createAmountSchema(optional),
		createPercentSchema(optional),
	]);

/** Validation schema for evolution param in {@link SalaryModel} in REQUIRED mode */
export const schema = createEvolutionSchema(false);
export type Model = z.infer<typeof schema>;

/** Validation schema for evolution param in {@link SalaryModel} in OPTIONAL mode */
export const schemaOptional = createEvolutionSchema(true);
export type ModelOptional = z.infer<typeof schemaOptional>;

/** Known type for {@link Model} */
export type Type = Model[typeof DISCRIMINATION_KEY];
/** List of available types for {@link Model} */
export const TYPES: readonly Type[] = schema.options.map(
	({ shape }) => shape[DISCRIMINATION_KEY].value,
);
