import { HttpRoute } from "@nna/core";

import { CreateCreditEvolutionHttpDefinitions } from "./evolution";
import { CreateCreditInterestHttpDefinitions } from "./interest";
import { type EntryHttpConfig } from "../entry.http";
import { CreateCreditClosureHttpDefinitions } from "./closure";
import type {
	CreditCreateDto,
	CreditDto,
	CreditQueryDto,
	CreditQueryResultsDto,
	CreditUpdateDto,
} from "./dtos";

/**
 * Create routes definitions for `Credit` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createCreditHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "creditId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			/** Note: even if, for now, there is only one closure by credit, the plural is ready for V2 */
			closures: CreateCreditClosureHttpDefinitions(
				baseById.addSegment("closures"),
			),
			evolutions: CreateCreditEvolutionHttpDefinitions(
				baseById.addSegment("evolutions"),
			),
			interests: CreateCreditInterestHttpDefinitions(
				baseById.addSegment("interests"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(query: CreditQueryDto) => Promise<CreditQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<CreditDto>>(),

			create: base.post<(body: CreditCreateDto) => Promise<CreditDto>>(),
			delete: baseById.delete<() => Promise<CreditDto>>(),
			update: baseById.patch<
				(body: CreditUpdateDto) => Promise<CreditDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Credit` features and children */
export type CreditHttpConfig = EntryHttpConfig["children"]["credits"];
/** HTTP specification for the `Entry/Credit` features */
export type CreditHttp = HttpRoute.Handlers<CreditHttpConfig["routes"]>;
