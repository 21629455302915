import { Id, toast, ToastOptions } from "react-toastify";

import { BannerVariants } from "../components/Banner";
import { Toast, ToastProps } from "../components/Toast";

/** Props/parameters for {@link showToast} */
export type ShowToastProps = Pick<ToastOptions, "autoClose" | "position"> &
	ToastProps;

/** Shows a toast */
export function showToast(props: ShowToastProps): Id {
	const {
		autoClose,
		onClose,
		position = "bottom-right",
		variant = BannerVariants.INFO,
	} = props;

	return toast(
		({ closeToast }) => (
			<Toast {...props} onClose={closeToast} variant={variant} />
		),
		{
			autoClose,
			closeButton: false,
			icon: false,
			position,
			toastId: props.title,
			type: variantToType[variant],

			// onClose is not triggered by the Toast component itself but here to catch all possible ways of closing the toast
			onClose,

			bodyStyle: {
				borderRadius: 8,
				margin: 0,
				padding: 0,
			},

			style: {
				backgroundColor: "transparent",
				borderRadius: 8,
				minHeight: 0,
				padding: 0,
			},
		},
	);
}

/**
 * We need the following to map the toast variant to the related Toastify css vars.
 * This way, the toast progress bar color is controlled by CSS vars located in our styles.css
 */
type VariantToType = {
	[variant in BannerVariants]: ToastOptions["type"];
};
const variantToType: VariantToType = {
	[BannerVariants.ERROR]: "error",
	[BannerVariants.INFO]: "info",
	[BannerVariants.SUCCESS]: "success",
	[BannerVariants.WARNING]: "warning",
};
