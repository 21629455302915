import { HttpRoute } from "@nna/core";

import type {
	StockDividendCreateDto,
	StockDividendDto,
	StockDividendQueryDto,
	StockDividendQueryResultsDto,
	StockDividendUpdateDto,
} from "./dtos";
import { type EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `StockDividend` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateStockDividendHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "dividendId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: StockDividendQueryDto,
					) => Promise<StockDividendQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<StockDividendDto>>(),

			create: base.post<
				(body: StockDividendCreateDto) => Promise<StockDividendDto>
			>(),
			delete: baseById.delete<() => Promise<StockDividendDto>>(),
			update: baseById.patch<
				(body: StockDividendUpdateDto) => Promise<StockDividendDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/StockDividend` features and children */
export type StockDividendHttpConfig =
	EntryHttpConfig["children"]["stocks"]["children"]["dividends"];
/** HTTP specification for the `Entry/StockDividend` features */
export type StockDividendHttp = HttpRoute.Handlers<
	StockDividendHttpConfig["routes"]
>;
