import { HttpRoute } from "@nna/core";

import { type EntryHttpConfig } from "../entry.http";
import type {
	Pillar1CreateDto,
	Pillar1Dto,
	Pillar1QueryDto,
	Pillar1QueryResultsDto,
	Pillar1UpdateDto,
} from "./pillar1.dto";

/** ... */
export function createPillar1HttpDef<const BASE extends HttpRoute.BuilderAny>(
	base: BASE,
) {
	const baseById = base.addSegment({
		param: "pillar1Id",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(query: Pillar1QueryDto) => Promise<Pillar1QueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<Pillar1Dto>>(),

			create: base.post<
				(body: Pillar1CreateDto) => Promise<Pillar1Dto>
			>(),
			delete: baseById.delete<() => Promise<Pillar1Dto>>(),
			update: baseById.patch<
				(body: Pillar1UpdateDto) => Promise<Pillar1Dto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

export type Pillar1HttpConfig = EntryHttpConfig["children"]["pillar1"];
export type Pillar1Http = HttpRoute.Handlers<Pillar1HttpConfig["routes"]>;
