import { HttpRoute } from "@nna/core";

import type {
	StockClosureCreateDto,
	StockClosureDto,
	StockClosureUpdateDto,
} from "./dtos";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `StockClosure` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateStockClosureHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "closureId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			create: base.post<
				(body: StockClosureCreateDto) => Promise<StockClosureDto>
			>(),
			delete: baseById.delete<() => Promise<StockClosureDto>>(),
			update: baseById.patch<
				(body: StockClosureUpdateDto) => Promise<StockClosureDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/StockClosure` features and children */
export type StockClosureHttpConfig =
	EntryHttpConfig["children"]["stocks"]["children"]["closures"];
/** HTTP specification for the `Entry/StockClosure` features */
export type StockClosureHttp = HttpRoute.Handlers<
	StockClosureHttpConfig["routes"]
>;
