import * as z from "zod";

/** Validation schema for {@link EntryCreateDto} */
export const entryCreateDtoSchema = z
	.object({
		// FOR V2 (only parent)
	})
	.strict();
/** Dto to create a `entry` */
export type EntryCreateDto = z.infer<typeof entryCreateDtoSchema>;
