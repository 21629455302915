import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { entryModelSchema } from "../entry.model";
import { RecipeKind } from "./recipe-kind";
import { dateCappedSchema } from "../common/date";

/** Zod Shape for {@link recipeModelSchema} */
export const recipeModelRawShape = {
	company: z.string().min(2),
	dateEnd: dateCappedSchema({}).describe("Date this recipe ends"),
	entitled: EntryCommon.customerSingleSchema.describe(
		"The customer that possess this recipe",
	),
	kind: RecipeKind.schema,
	taxable: z.boolean().describe("Is this recipe taxable"),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link RecipeModel} */
export const recipeModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(recipeModelRawShape),
		{
			...recipeModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});
/** The recipe of an `Entry` */
export type RecipeModel = z.infer<typeof recipeModelSchema>;
