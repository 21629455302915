import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { stockModelSchema } from "../stock.model";

export const typeOfStockFiscalSchema = z.enum([
	"FIXED_INTEREST",
	"VARIABLE_INTEREST",
]);
export type TypeOfStockFiscal = z.infer<typeof typeOfStockFiscalSchema>;
/** Zod Shape for {@link stockFiscalModelSchema} */
export const stockFiscalModelRawShape = {
	// TODO: Templating: Add the specific field of the model
	date: dateCappedSchema({}),
	percent: z.number().gte(0).lte(100),
	type: typeOfStockFiscalSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link StockModel} */
export const stockFiscalModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(stockFiscalModelRawShape),
		{
			...stockFiscalModelRawShape,
			fkStock: stockModelSchema.shape._id,
		},
	),
});

export type StockFiscalModel = z.infer<typeof stockFiscalModelSchema>;
