import { OmitKnown } from "@nna/core";
import { Field, FieldProps } from "formik";

import { DotPath } from "../../../../utils/types/DotPath";
import { Switch, SwitchProps } from "../Switch";

/** Props for {@link FormikSwitch} */
export interface FormikSwitchProps<T extends object | string>
	extends OmitKnown<SwitchProps, "checked" | "name" | "onBlur" | "onChange"> {
	/** Name of the input */
	name: T extends object ? DotPath<T> : T;
}
/**
 * Wraps {@link Switch} for a formik usage.
 *
 * Do not forget to set an initial value with real boolean (or formik with do non-sense)
 */
export function FormikSwitch<T extends object | string>(
	props: FormikSwitchProps<T>,
) {
	const { name, ...switchProps } = props;

	return (
		<Field name={name} type="checkbox">
			{({ field: { onChange: _, ...field }, form }: FieldProps) => (
				<Switch
					{...switchProps}
					{...field}
					onCheckChange={checked =>
						void form.setFieldValue(name, checked)
					}
				/>
			)}
		</Field>
	);
}
