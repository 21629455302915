import * as joy from "@mui/joy";

import { textBody, textSmall } from "../../../ui-layout/styles/textStyles";
import { theme } from "../../../ui-layout/styles/theme";
import * as dateIsoUtils from "../input.date-iso.utils";

// The `InputProps` from the lib is not really the one of {@link InputJoy}
type InputJoyProps = Parameters<typeof joy.Input>[0];

/** A pseudo-type for 'date'. Kinda allows `null` as a value. */
export type InputPropsDateType = "date-iso";
/**
 * A pseudo-type for 'currency'
 * 	it is a "sub-type" of number (and currently changes nothing to the input).
 *
 * (Does not seem really to be a good way but ...)
 */
export type InputCurrencyType = "number-currency";
export type InputPropsType =
	| InputCurrencyType
	| InputPropsDateType
	| "date"
	| "email"
	| "number"
	| "password"
	| "text";

/** Props for {@link Input} */
export interface InputProps
	extends Pick<
		InputJoyProps,
		| "autoFocus"
		| "className"
		| "disabled"
		| "endDecorator"
		| "error"
		| "name"
		| "onBlur"
		| "onChange"
		| "onFocus"
		| "onKeyDown"
		| "onKeyUp"
		| "placeholder"
		| "startDecorator"
		| "sx"
		| "tabIndex"
		| "value"
	> {
	/** The type of the input */
	type?: InputPropsType;
}

/**
 * Base component for inputs
 *
 * @param props to generate the component
 */
export function Input(props: InputProps): JSX.Element {
	if (props.type === "number-currency") {
		// This "sub-type" changes nothing (at the moment)
		return <Input {...props} type="number" />;
	}

	if (props.type === "date-iso") {
		const { onChange, value } = props;

		const onChangeDate: typeof onChange = onChange
			? event => onChange(dateIsoUtils.transformEventToDateISO(event))
			: onChange;
		const valueDate = dateIsoUtils.transformValueToDateISO(value);

		return (
			<Input
				{...props}
				onChange={onChangeDate}
				type="date"
				value={valueDate}
			/>
		);
	}

	const { error, name, value, ...rest } = props;
	// For when we receive 'null' from the backend / initial values (== nullable fields)
	const valueClean = value === null ? "" : value;

	return (
		<InputStyled
			data-testid="ui/input"
			id={name}
			{...rest}
			error={!!error}
			name={name}
			value={valueClean}
		/>
	);
}

const InputStyled = joy.styled(joy.Input)<{
	$applyCellStyles?: boolean;
	error?: boolean;
}>`
	border-radius: 4px;
	background-color: ${theme.palette.white.primary};
	border: none;
	box-shadow: none;
	color: ${theme.palette.blue.primary};
	height: 42px;
	min-height: 42px;
	outline: ${({ error }) => `1px solid ${error ? theme.palette.transactional.danger : theme.palette.grey[40]}`};
	padding: 4px 8px 4px 12px !important;
	${textBody}
	--Input-focusedThickness: 0;

	& .${joy.inputClasses.input}::placeholder {
		color: ${theme.vars.palette.grey[50]};
	}

	&.${joy.inputClasses.disabled} {
		color: ${theme.vars.palette.grey[45]};
		cursor: not-allowed !important;
		pointer-events: all !important;
	}

	&.${joy.inputClasses.disabled} .${joy.inputClasses.input} {
		cursor: not-allowed !important;
		pointer-events: all !important;
	}

	&.${joy.inputClasses.focused} {
		outline: 2px solid ${theme.palette.blue.primary};
	}

	& .${joy.inputClasses.startDecorator} {
		color: ${theme.vars.palette.blue.primary};
		margin-right: 4px;
		${textSmall}
	}

	& .${joy.inputClasses.endDecorator} {
		color: ${theme.vars.palette.blue.primary};
		${textSmall}
	}
,
`;
