import styled from "@emotion/styled";

/** Style-props for {@link Lined} */
export interface LinedProps {
	/**
	 * Reverse the view (right to left)
	 *
	 * @default false
	 */
	reverse?: boolean;
}

/** Line (horizontally) its the content */
export const Lined = styled.div<LinedProps>`
	align-items: center;
	display: flex;
	flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
`;
