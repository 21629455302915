import { OptionProps } from "@mui/joy";
import { OmitKnown } from "@nna/core";
import { useMemo } from "react";

import { AutocompleteProps } from "./AutoComplete";
import { Select, SelectProps } from "./Select/Select";
import { SelectOption } from "./Select/SelectOption";

/** Some utils function with {@link SelectWithOptions} */
export const SelectUtils = {
	/**
	 * Extracts the options that selected from its value
	 *
	 * @param params for the extraction
	 * @returns the options that are selected
	 */
	extractSelectedOptions: function <V>(
		params: Pick<
			SelectWithOptionsProps<V, boolean>,
			"isOptionEqualToValue" | "options" | "value"
		>,
	) {
		const {
			isOptionEqualToValue = SelectUtils.isOptionEqualToValue,
			options,
			value,
		} = params;

		if (!value) {
			return [];
		}

		// any value as an array
		const values = (Array.isArray(value) ? value : [value]) as V[];
		return options.filter(option =>
			values.some(value => isOptionEqualToValue(option.value, value)),
		);
	},
	/**
	 * Default `isOptionEqualToValue` used in {@link SelectWithOptions}.
	 * It is a simple comparison
	 */
	isOptionEqualToValue: ((option: unknown, value: unknown) =>
		option === value) satisfies AutocompleteProps<
		unknown,
		false,
		false,
		false
	>["isOptionEqualToValue"],
	/**
	 * Transforms the values to file the expected values of a select is multiple or not
	 *
	 * @param values to transform
	 * @param multiple is in 'multiple' mode
	 * @returns the values for the correct mode
	 */
	transformValues: function <V, Multiple extends boolean>(
		values: readonly V[],
		multiple?: Multiple,
	) {
		type SelectValue<M extends boolean> = SelectProps<
			// @ts-expect-error -- `joy` constraint is a 'non-null', but it is in fact allowed ...
			V,
			M
		>["value"];

		if (multiple) {
			return values.slice() satisfies SelectValue<true>;
		}

		return (values.length ? values[0] : null) satisfies SelectValue<false>;
	},
};

/** Options in {@link SelectOptionsProps } */
export interface SelectOptionsProps<V> extends OmitKnown<OptionProps, "value"> {
	/** Unique key (mostly for React loop) */
	key: string;
	/** Value of this option */
	value: V;
}
/** Props for {@link SelectOptionsProps} */
export interface SelectWithOptionsProps<V, Multiple extends boolean>
	extends OmitKnown<SelectProps<NonNullable<V>, Multiple>, "children">,
		Pick<
			AutocompleteProps<V, false, false, false>,
			"isOptionEqualToValue"
		> {
	/** Option to show in the select */
	options: ReadonlyArray<SelectOptionsProps<V>>;
}
/**
 * A override of {@link Select} with the options "type-linked" with the value
 *
 * @param props to generate the component
 */
export function SelectWithOptions<V, Multiple extends boolean>(
	props: SelectWithOptionsProps<V, Multiple>,
) {
	const { isOptionEqualToValue, multiple, options, value, ...selectProps } =
		props;

	// As the value could be an object,
	//	the value used should be the one in a option to avoid unnecessary changes
	const valueFromOption = useMemo(() => {
		const valuesSelected = SelectUtils.extractSelectedOptions({
			isOptionEqualToValue,
			options,
			value,
		}).map(({ value }) => value);

		return SelectUtils.transformValues(valuesSelected, multiple);
	}, [isOptionEqualToValue, multiple, options, value]);

	return (
		<Select
			{...selectProps}
			multiple={multiple}
			value={valueFromOption as never}
		>
			{options.map(({ key, ...option }) => (
				<SelectOption
					{...option}
					key={key}
					data-testid={`ui/select-with-option/${key}`}
				/>
			))}
		</Select>
	);
}
