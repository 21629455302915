import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { creditModelSchema } from "../credit.model";

/** Validation schema for {@link CreditEvolutionType} */
export const creditEvolutionTypeSchema = z.enum(["INCREASE", "REFUND"]);
export type CreditEvolutionType = z.infer<typeof creditEvolutionTypeSchema>;

/** Zod Shape for {@link creditEvolutionModelSchema} */
export const creditEvolutionModelRawShape = {
	amount: z.number().describe("Amount for this evolution (only for visual)"),
	period: EntryCommon.periodDateSchema(),
	type: creditEvolutionTypeSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link CreditModel} */
export const creditEvolutionModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(creditEvolutionModelRawShape),
		{
			...creditEvolutionModelRawShape,
			fkCredit: creditModelSchema.shape._id,
		},
	),
});
export type CreditEvolutionModel = z.infer<typeof creditEvolutionModelSchema>;
