import styled from "@emotion/styled";
import * as joy from "@mui/joy";
import { useTranslation } from "next-i18next";

import { textBody } from "../../ui-layout/styles/textStyles";
import { theme } from "../../ui-layout/styles/theme";

/** Props for {@link Switch} */
export interface SwitchProps extends joy.SwitchProps {
	/** Name to set in the nested input */
	name?: string;
	/** Event with only the state of the check change */
	onCheckChange?: (checked: boolean) => void;
}

/**
 * Basic switch "input".
 *
 * Set a `endDecorator` to remove/replace the default "yes/now"
 */
export function Switch(props: SwitchProps) {
	const { t } = useTranslation();

	const {
		checked,
		name,
		onChange = () => void 0,
		onCheckChange = () => void 0,
		...switchProps
	} = props;

	return (
		<SwitchStyled
			data-testid="ui/switch"
			endDecorator={
				checked ? t("common.state.yes") : t("common.state.no")
			}
			slotProps={{ input: { name, type: "checkbox" } }}
			{...switchProps}
			checked={checked}
			onChange={event => {
				onChange(event);
				onCheckChange(event.target.checked);
			}}
		/>
	);
}

const { switchClasses } = joy;
const SwitchStyled = styled(joy.Switch)`
	/* stylelint-disable */
	/* Because it is not happy with the css var names */

	--Switch-trackHeight: 10px;
	--Switch-trackWidth: 30px;
	--Switch-trackBackground: ${theme.palette.grey[40]};
	--Switch-trackBorderColor: ${theme.palette.grey[40]};
	--Switch-thumbBackground: ${theme.palette.grey[30]};
	--Switch-thumbShadow: ${theme.palette.grey[30]};

	&:hover {
		--Switch-trackBackground: ${theme.palette.grey[40]};
	}
	&.${switchClasses.checked} {
		--Switch-trackBackground: ${theme.palette.grey[40]};
		--Switch-trackBorderColor: ${theme.palette.grey[40]};
		--Switch-thumbBackground: ${theme.palette.blue.primary};
		--Switch-thumbShadow: ${theme.palette.blue.primary};

		&:hover {
			--Switch-trackBackground: ${theme.palette.grey[40]};
		}
	}

	&.${switchClasses.disabled} .${switchClasses.input} {
		cursor: not-allowed !important;
		pointer-events: all !important;
	}
	&.${switchClasses.disabled} {
		--Switch-trackBackground: ${theme.palette.grey[40]};
		--Switch-thumbBackground: ${theme.palette.grey[30]};
		--Switch-thumbShadow: ${theme.palette.grey[30]};

		&:hover {
			--Switch-trackBackground: ${theme.palette.grey[40]};
		}
	}
	&.${switchClasses.disabled}.${switchClasses.checked} {
		--Switch-trackBackground: ${theme.palette.grey[40]};
		--Switch-thumbBackground: ${theme.palette.grey[45]};
		--Switch-thumbShadow: ${theme.palette.grey[45]};

		&:hover {
			--Switch-trackBackground: ${theme.palette.grey[40]};
		}
	}

	& .${switchClasses.endDecorator} {
		${textBody}
	}
`;
