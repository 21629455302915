import styled from "@emotion/styled";
import React from "react";

import { EmptyCellPlaceholder } from "./EmptyCellPlaceholder";
import { textSmall } from "../../../ui-layout/styles/textStyles";
import { theme } from "../../../ui-layout/styles/theme";

const dataTestid = "cell-unfocused/generic";

/** Props for {@link UnfocusedGenericValue} */
export interface UnfocusedGenericValueProps {
	/** The current value of the input */
	children?: React.ReactNode;
	/** Is the input disabled (and thus the focus mode) */
	disabled?: boolean;
	/** An endDecorator that is displayed after the unfocused value (à-la input) */
	endDecorator?: React.ReactNode;
	/** Text to show when empty */
	placeholder?: string;
	/** A startDecorator that is displayed before the unfocused value (à-la input) */
	startDecorator?: React.ReactNode;
}
/** Generic unfocused renderer */
export function UnfocusedGenericValue(props: UnfocusedGenericValueProps) {
	const { children, disabled, endDecorator, placeholder, startDecorator } =
		props;

	return children ? (
		<UnfocusedStyled data-testid={dataTestid} disabled={disabled}>
			{startDecorator && <Decorator>{startDecorator}</Decorator>}
			<Content>{children}</Content>
			{endDecorator && <Decorator>{endDecorator}</Decorator>}
		</UnfocusedStyled>
	) : (
		<EmptyCellPlaceholder>{placeholder}</EmptyCellPlaceholder>
	);
}

const UnfocusedStyled = styled.div<{
	disabled?: boolean;
}>`
	align-items: center;
	display: flex;
	gap: 4px;
	overflow: hidden;

	${props => props.disabled && `color: ${theme.palette.grey[45]};`}
	${props => props.disabled && "cursor: not-allowed;"}
`;

const Content = styled.span`
	overflow: hidden;
`;

const Decorator = styled.span`
	color: ${theme.palette.blue.primary};
	${textSmall};
`;
