import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { entryModelSchema } from "../../entry.model";

/** Zod Shape for {@link childModelSchema} */
export const childModelRawShape = {
	birthday: dateCappedSchema({}),
	chargeOf: EntryCommon.customerSchema.describe(
		"The customer in charge of the child",
	),
	chargeOfCustomer1Percent: EntryCommon.boundedPercentageSchema
		.nullable()
		.describe("% of charge for customer1"),
	chargeOfCustomer2Percent: EntryCommon.boundedPercentageSchema
		.nullable()
		.describe("% of charge for customer2"),
	endOfStudies: dateCappedSchema({}).nullable(),
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	stateAllocation: z
		.string()
		.nullable()
		.describe("On which canton for allocation"),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link ChildModel} used by the DTO, it ignore the refine done for the main model */
export const childModelDTOSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(childModelRawShape),
		{
			...childModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});

/** Validation schema for {@link ChildModel} */
export const childModelSchema = childModelDTOSchema.refine(
	data => {
		const percent1 = data.chargeOfCustomer1Percent || 0;
		const percent2 = data.chargeOfCustomer2Percent || 0;
		return percent1 + percent2 <= 100;
	},
	{
		message:
			"La somme des charges pour l'enfant ne doit pas dépasser les 100%", // TODO: move this translation elsewhere
		path: ["chargeOfCustomer1Percent"],
	},
);

export type ChildModel = z.infer<typeof childModelSchema>;
