import { HttpRoute } from "@nna/core";

import type {
	CreditClosureCreateDto,
	CreditClosureDto,
	CreditClosureUpdateDto,
} from "./dtos";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `CreditClosure` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateCreditClosureHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "closureId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			create: base.post<
				(body: CreditClosureCreateDto) => Promise<CreditClosureDto>
			>(),
			delete: baseById.delete<() => Promise<CreditClosureDto>>(),
			update: baseById.patch<
				(body: CreditClosureUpdateDto) => Promise<CreditClosureDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/CreditClosure` features and children */
export type CreditClosureHttpConfig =
	EntryHttpConfig["children"]["credits"]["children"]["closures"];
/** HTTP specification for the `Entry/CreditClosure` features */
export type CreditClosureHttp = HttpRoute.Handlers<
	CreditClosureHttpConfig["routes"]
>;
