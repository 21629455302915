import { HttpRoute } from "@nna/core";

import type {
	AccountClosureCreateDto,
	AccountClosureDto,
	AccountClosureUpdateDto,
} from "./dtos";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `AccountClosure` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createAccountClosureHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "closureId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			create: base.post<
				(body: AccountClosureCreateDto) => Promise<AccountClosureDto>
			>(),
			delete: baseById.delete<() => Promise<AccountClosureDto>>(),
			update: baseById.patch<
				(body: AccountClosureUpdateDto) => Promise<AccountClosureDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/AccountClosure` features and children */
export type AccountClosureHttpConfig =
	EntryHttpConfig["children"]["accounts"]["children"]["closures"];
/** HTTP specification for the `Entry/AccountClosure` features */
export type AccountClosureHttp = HttpRoute.Handlers<
	AccountClosureHttpConfig["routes"]
>;
