import { Entry } from "~/common";

import { EntryHttpClients } from "../../../clients";
import { RepositoryFromHttp } from "../../common";

const httpClient = EntryHttpClients.AccountClosureHttpClient;

/** Repository options of 'entry/account/closures' HTTP client to use with 'react-query' */
export const AccountClosureRepository = {
	create: { mutationFn: ([params, body]) => httpClient.create(params, body) },
	delete: { mutationFn: ([params]) => httpClient.delete(params) },
	update: { mutationFn: ([params, body]) => httpClient.update(params, body) },
} as const satisfies RepositoryFromHttp<Entry.AccountClosureHttp>;
