import { Model, OmitKnown } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { dateCappedSchema } from "./date";

/** Zod Shape for {@link closureModelSchema} */

/** Validation schema for {@link LoanClosureDto} */

/**
 * Create a base validation schema for 'closure' models.
 * `date` is mandatory
 *
 * @param shapeTracked content to be tracked by the 'change-state' (eg. dates, values)
 * @param shapeUntracked content to not be tracked by the 'change-state' (eg. foreign keys)
 * @returns validation schema
 */
export function createClosureModelSchema<
	const S1 extends z.ZodRawShape,
	const S2 extends z.ZodRawShape,
>(shapeTracked: S1, shapeUntracked: S2) {
	const shape = {
		...shapeTracked,
		date: dateCappedSchema({}).describe(
			"The date that the object is/will close",
		),
	} as const;

	return z.object({
		...Model.schema.shape,
		...addChangeStateSchemaToShape(
			createChangeStateSchemaFromShapeV0(shape),
			shape,
		),
		...shapeUntracked,
	} as const);
}

const emptyShape = {} as const;
/**
 * Base type for 'closure' models, without foreign keys
 *
 * Note for V0-1: even if there is a 'change-state' property,
 * 	it is "ignored" until V2 as it is a "exist-or-not" model.
 * (In V2, a closure might be registered as UPDATED/DELETED in a entry)
 */
export type ClosureModel = OmitKnown<
	z.infer<
		ReturnType<
			typeof createClosureModelSchema<
				typeof emptyShape,
				typeof emptyShape
			>
		>
	>,
	"changeState"
>;
