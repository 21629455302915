import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../source-amount/source-amount.actions";
import { transferableClosureModelSchema } from "../transferable-closure.model";

/** Validation schema for {@link TransferableClosureCreateDto} */
export const transferableClosureCreateDtoSchema = z.object({
	date: transferableClosureModelSchema.shape.date,
	funds: z.lazy(() => sourceAmountActionsSchema),
});
export type TransferableClosureCreateDto = z.infer<
	typeof transferableClosureCreateDtoSchema
>;

/** Validation schema for {@link TransferableClosureUpdateDto} */
export const transferableClosureUpdateDtoSchema =
	transferableClosureCreateDtoSchema.partial();
export type TransferableClosureUpdateDto = z.infer<
	typeof transferableClosureUpdateDtoSchema
>;
