import { Schemas } from "@nna/core";
import { TFunction } from "next-i18next";

import { invalidString } from "./invalid-string";
import { invalidType } from "./invalid-type";
import { formatDate } from "../../utils/formatters";

export type Fallback = (issue: Schemas.Issue.Issue) => string;

/**
 * Translates an {@link Issue}
 *
 * @param t translate function
 * @param issue to translate
 * @param fallback fallback if no "more specific" translation is found
 * @returns translation
 */
export function translate(
	t: TFunction,
	issue: Schemas.Issue.Issue,
	fallback?: Fallback,
): string {
	return Schemas.Issue.visitWithFallback<string>(
		issue,
		fallback ?? (() => t("errors.zod._default")),
		{
			invalid_date: () => t("errors.zod.invalid-date"),
			invalid_enum_value: ({ options, received }) =>
				t("errors.zod.invalid-enum", {
					options: options.join(", "),
					received,
				}),
			invalid_literal: ({ expected, received }) =>
				t("errors.zod.invalid-literal", { expected, received }),
			invalid_string: issue => invalidString(t, issue),
			invalid_type: issue => invalidType(t, issue),
			too_big: ({ inclusive, maximum, type }) => {
				const tParams = {
					maximum:
						type === "date"
							? formatDate(t, maximum as number)
							: maximum,
				};

				if (type === "string") {
					return inclusive
						? t("errors.zod.lte-string", tParams)
						: t("errors.zod.lt-string", tParams);
				}
				return inclusive
					? t("errors.zod.lte", tParams)
					: t("errors.zod.lt", tParams);
			},
			too_small: ({ inclusive, minimum, type }) => {
				const tParams = {
					minimum:
						type === "date"
							? formatDate(t, minimum as number)
							: minimum,
				};

				if (type === "string") {
					return inclusive
						? t("errors.zod.gte-string", tParams)
						: t("errors.zod.gt-string", tParams);
				}
				return inclusive
					? t("errors.zod.gte", tParams)
					: t("errors.zod.gt", tParams);
			},
		},
	);
}
