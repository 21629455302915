import * as z from "zod";

/** Validation schema for {@link PlanUpdateDto} */
export const planUpdateDtoSchema = z
	.object({
		// TODO: FG-17
	})
	.strict()
	.partial();

/** DTO to update a plan */
export type PlanUpdateDto = z.infer<typeof planUpdateDtoSchema>;
