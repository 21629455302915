import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	loanInterestModelRawShape,
	loanInterestModelSchema,
} from "../loan.interest.model";

/** Validation schema for {@link LoanInterestDto} */
export const loanInterestDtoSchema = loanInterestModelSchema;
export type LoanInterestDto = z.infer<typeof loanInterestDtoSchema>;

/** Validation schema for {@link LoanInterestCreateDto} */
export const loanInterestCreateDtoSchema = z.object(loanInterestModelRawShape);
/** Dto to create a `loan */
export type LoanInterestCreateDto = z.infer<typeof loanInterestCreateDtoSchema>;

/** Validation schema for {@link LoanInterestQueryDto} */
export const loanInterestQueryDtoSchema = createQueryObjectSchema(
	loanInterestDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `loanInterest` */
export type LoanInterestQueryDto = z.infer<typeof loanInterestQueryDtoSchema>;

/** Validation schema for {@link LoanInterestQueryResultsDto} */
export const loanInterestQueryResultsDtoSchema = createQueryResultsSchema(
	loanInterestDtoSchema,
);
/** Results for `loans` from a query */
export type LoanInterestQueryResultsDto = z.infer<
	typeof loanInterestQueryResultsDtoSchema
>;

/** Validation schema for {@link LoanInterestUpdateDto} */
export const loanInterestUpdateDtoSchema =
	loanInterestCreateDtoSchema.partial();
/** Dto to update a `loan */
export type LoanInterestUpdateDto = z.infer<typeof loanInterestUpdateDtoSchema>;
