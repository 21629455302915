import { HttpRoute } from "@nna/core";

import { type EntryHttpConfig } from "../entry.http";
import { createAccountClosureHttpDefinitions } from "./closure";
import type {
	AccountCreateDto,
	AccountDto,
	AccountQueryDto,
	AccountQueryResultsDto,
	AccountUpdateDto,
} from "./dtos";

/**
 * Create routes definitions for `Account` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createAccountHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "accountId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			/** Note: even if, for now, there is only one closure by account, the plural is ready for V2 */
			closures: createAccountClosureHttpDefinitions(
				baseById.addSegment("closures"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(query: AccountQueryDto) => Promise<AccountQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<AccountDto>>(),

			create: base.post<
				(body: AccountCreateDto) => Promise<AccountDto>
			>(),
			delete: baseById.delete<() => Promise<AccountDto>>(),
			update: baseById.patch<
				(body: AccountUpdateDto) => Promise<AccountDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Account` features and children */
export type AccountHttpConfig = EntryHttpConfig["children"]["accounts"];
/** HTTP specification for the `Entry/Account` features */
export type AccountHttp = HttpRoute.Handlers<AccountHttpConfig["routes"]>;
