import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	stockDividendModelRawShape,
	stockDividendModelSchema,
} from "../stock.dividend.model";

/** Validation schema for {@link StockDividendDto} */
export const stockDividendDtoSchema = stockDividendModelSchema;
export type StockDividendDto = z.infer<typeof stockDividendDtoSchema>;

/** Validation schema for {@link StockDividendCreateDto} */
export const stockDividendCreateDtoSchema = z.object(
	stockDividendModelRawShape,
);
/** Dto to create a `stock */
export type StockDividendCreateDto = z.infer<
	typeof stockDividendCreateDtoSchema
>;

/** Validation schema for {@link StockDividendQueryDto} */
export const stockDividendQueryDtoSchema = createQueryObjectSchema(
	stockDividendDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `stockDividend` */
export type StockDividendQueryDto = z.infer<typeof stockDividendQueryDtoSchema>;

/** Validation schema for {@link StockDividendQueryResultsDto} */
export const stockDividendQueryResultsDtoSchema = createQueryResultsSchema(
	stockDividendDtoSchema,
);
/** Results for `stocks` from a query */
export type StockDividendQueryResultsDto = z.infer<
	typeof stockDividendQueryResultsDtoSchema
>;

/** Validation schema for {@link StockDividendUpdateDto} */
export const stockDividendUpdateDtoSchema =
	stockDividendCreateDtoSchema.partial();
/** Dto to update a `stock */
export type StockDividendUpdateDto = z.infer<
	typeof stockDividendUpdateDtoSchema
>;
