import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { recipeModelSchema } from "../recipe.model";

/** Validation schema for the expense in {@link SalaryModel} */
export const salaryExpenseSchema = z.discriminatedUnion("effective", [
	z.object({
		amount: EntryCommon.amountSchema,
		effective: z.literal(true),
	}),
	z.object({
		amount: EntryCommon.amountSchema
			.default(0)
			.or(z.literal("").transform(() => 0)), // FORMIK_456: To handle Formik empty string state
		effective: z.literal(false),
	}),
]) satisfies z.ZodType<SalaryExpense, z.ZodTypeDef, unknown>;
/** The salary expense property */
export interface SalaryExpense {
	/** The amount of the expense (useless when not effective) */
	amount: number;
	/** Is the expense effective (mean meaning 'flat'/included) */
	effective: boolean;
}

/** Zod Shape for {@link salaryModelSchema} */
export const salaryModelRawShape = {
	activityRate: EntryCommon.boundedPercentageSchema,
	annualGross: EntryCommon.amountSchema,
	annualNet: EntryCommon.amountSchema,
	dateStart: dateCappedSchema({}).describe("Start date of this salary"),
	evolution: z.boolean().describe("Is there an evolution in this salary"),
	evolutionParam: EntryCommon.Evolution.schema.describe(
		"Evolution parameters when enabled. To ignore when disabled",
	),

	expenseFormation: salaryExpenseSchema,
	expenseMeal: salaryExpenseSchema,
	expenseOther: salaryExpenseSchema,
	expenseOutside: salaryExpenseSchema,
	expenseTransport: salaryExpenseSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link SalaryModel} */
export const salaryModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(salaryModelRawShape),
		{
			...salaryModelRawShape,
			fkRecipe: recipeModelSchema.shape._id,
		},
	),
});
/** The salary of a `Recipe` */
export type SalaryModel = z.infer<typeof salaryModelSchema>;
