import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../source-amount/source-amount.actions";
import { sourceAmountDtoSchema } from "../../../source-amount/source-amount.model";
import {
	loanEvolutionModelRawShape,
	loanEvolutionModelSchema,
} from "../loan.evolution.model";

/** Validation schema for {@link LoanEvolutionDto} */
export const loanEvolutionDtoSchema = z.object({
	...loanEvolutionModelSchema.shape,
	funds: z.array(z.lazy(() => sourceAmountDtoSchema)),
});
export type LoanEvolutionDto = z.infer<typeof loanEvolutionDtoSchema>;

/** Validation schema for {@link LoanEvolutionCreateDto} */
export const loanEvolutionCreateDtoSchema = z.object({
	...loanEvolutionModelRawShape,
	funds: sourceAmountActionsSchema,
});
/** Dto to create a `loan */
export type LoanEvolutionCreateDto = z.infer<
	typeof loanEvolutionCreateDtoSchema
>;

/** Validation schema for {@link LoanEvolutionUpdateDto} */
export const loanEvolutionUpdateDtoSchema =
	loanEvolutionCreateDtoSchema.partial();
/** Dto to update a `loan */
export type LoanEvolutionUpdateDto = z.infer<
	typeof loanEvolutionUpdateDtoSchema
>;

/** Validation schema for {@link LoanEvolutionQueryDto} */
export const loanEvolutionQueryDtoSchema = createQueryObjectSchema(
	loanEvolutionDtoSchema,
	{ coerce: true, strict: true },
).strict();

/** DTO used to filter `loanEvolution` */
export type LoanEvolutionQueryDto = z.infer<typeof loanEvolutionQueryDtoSchema>;

/** Validation schema for {@link LoanEvolutionQueryResultsDto} */
export const loanEvolutionQueryResultsDtoSchema = createQueryResultsSchema(
	loanEvolutionDtoSchema,
);
/** Results for `loans` from a query */
export type LoanEvolutionQueryResultsDto = z.infer<
	typeof loanEvolutionQueryResultsDtoSchema
>;
