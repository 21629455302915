import { useField, useFormikContext } from "formik";

/**
 * This hook 'deeply' checks if a formik field has an error:
 * - it checks if the field has an error
 * - it checks if the field has been touched
 * - it checks if the form has been submitted
 *
 * If it has an error AND either was touched OR submitted, it returns the error.
 * Otherwise, it returns false.
 *
 * @param name the name of the field
 * @returns the error or false.
 */
export function useFormikErrorDeepCheck(name: string) {
	const [, meta] = useField(name);
	const hasBeenSubmitted = 0 < useFormikContext().submitCount;
	return !!(meta.error && (hasBeenSubmitted || meta.touched)) && meta.error;
}
