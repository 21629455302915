import * as z from "zod";

import { createMultilineSchema } from "../../common/multiline";
import { sourceAmountActionsSchema } from "../../source-amount/source-amount.actions";
import { accountModelRawShape, accountTypeSchema } from "../account.model";
import { accountMovementSchema } from "../account.movement";

export const accountTypeCreateSchema = accountTypeSchema.exclude(["EXTERNAL"]);

const { direction: _1, source: _, ...dtoShape } = accountMovementSchema.shape;

/** Validation schema for {@link AccountCreateDto} */
export const accountCreateDtoSchema = z
	.object({
		...accountModelRawShape,
		funds: z.lazy(() => sourceAmountActionsSchema),
		movements: createMultilineSchema(
			accountMovementSchema,
			z.object(dtoShape).partial(),
		)
			.default([])
			.describe("To update only account <=> account movements"),
		type: accountTypeCreateSchema,
	})
	.strict();
/** Dto to create a `account */
export type AccountCreateDto = z.infer<typeof accountCreateDtoSchema>;
