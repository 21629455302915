import * as dateFns from "date-fns";

import type { SalaryModel } from "../salary.model";

/** Minimal structure for a salary with the date start */
export type SalaryWithDateStart = Pick<SalaryModel, "dateStart">;

/**
 * Gets the salary that is the closest to the given date reference.
 * The "date" of the salary if taken from `dateStart`, its "endDate" is the "startDate" of the next one.
 *
 * 1. If {@link dateRef} is smaller than any salary => more recent salary
 * 2. If {@link dateRef} is older than any salary => oldest salary
 * 3. Otherwise, the salary with its "time-range" in the given {@link dateRef}
 *
 * @param salaries to extract the closest-salary from
 * @param dateRef a  @default now
 * @returns the salary closest to the given date, null when no salaries given
 */
export function getCloserToDate<T extends SalaryWithDateStart>(
	salaries: readonly T[],
	dateRef = new Date(),
): T | null {
	if (salaries.length === 0) {
		return null;
	}

	const fn = (salaries: readonly T[], current: T): T => {
		if (salaries.length === 0) {
			// Nothing else => return the remaining
			return current;
		}

		const [first, ...rest] = salaries;
		if (dateFns.isBefore(dateRef, first.dateStart)) {
			// The date of the first element is greater than the referential date
			//	=> return the current one
			return current;
		}

		return fn(rest, first);
	};

	// To ensure the order
	const [first, ...rest] = salaries
		.slice()
		.sort(({ dateStart: a }, { dateStart: b }) => dateFns.compareAsc(a, b));
	return fn(rest, first);
}
