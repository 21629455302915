import * as z from "zod";

import { loanClosureDtoSchema } from "../../../loan/closure/dtos/loan-closure.dto";
import { loanClosureModelSchema } from "../../../loan/closure/loan-closure.model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "loan-closure";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkLoanClosure: loanClosureModelSchema.shape._id,
});
/** Movement Group representation of a {@link loanClosureModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	loanClosure: z.lazy(() => loanClosureDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
