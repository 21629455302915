import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { transferableClosureDtoSchema } from "../closure/dtos/transferable-closure.dto";
import { transferableModelSchema } from "../transferable.model";

/** Base schema for {@link transferableDtoSchema} with reduced relations */
export const transferableBaseDtoSchema = transferableModelSchema;

/** Validation schema for {@link TransferableDto} */
export const transferableDtoSchema = z.object({
	...transferableModelSchema.shape,
	closure: z
		.lazy(() => transferableClosureDtoSchema)
		.nullable()
		.describe(
			"The closure of this transferable.<br>`null` when there is none",
		),
});

export type TransferableDto = z.infer<typeof transferableDtoSchema>;

/** Validation schema for {@link TransferableQueryDto} */
export const transferableQueryDtoSchema = createQueryObjectSchema(
	transferableDtoSchema,
	{ coerce: true, strict: true },
).strict();
/** DTO used to filter `transferable` */
export type TransferableQueryDto = z.infer<typeof transferableQueryDtoSchema>;

/** Validation schema for {@link TransferableQueryResultsDto} */
export const transferableQueryResultsDtoSchema = createQueryResultsSchema(
	transferableDtoSchema,
);
/** Results for `transferables` from a query */
export type TransferableQueryResultsDto = z.infer<
	typeof transferableQueryResultsDtoSchema
>;
