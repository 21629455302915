import { Entry } from "~/common";

import { EntryHttpClients } from "../../clients";
import { RepositoryFromHttp } from "../common";

const { routes } = Entry.ENTRY_HTTP_CONFIG.children.sources;
const httpClient = EntryHttpClients.SourceHttpClient;

/** Repository options of 'entry/Source' HTTP client to use with 'react-query' */
export const SourceRepository = {
	findAndCount: (params, query) => ({
		queryFn: () => httpClient.findAndCount(params, query),
		queryKey: [...routes.findAndCount.path(params).split("/"), query],
	}),
} as const satisfies RepositoryFromHttp<Entry.SourceHttp>;
