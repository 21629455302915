import { Entry } from "~/common";

import { fetchRequest } from "../../../../utils/utils";
import { getRequestMethodFromHttpMethod } from "../../../utils";

const { routes } =
	Entry.ENTRY_HTTP_CONFIG.children.children.children.interruptions;

/** Http client for `Entry/information/child` features */
export const InterruptionHttpClient: Entry.InterruptionHttp = {
	findAndCount: (params, query) =>
		fetchRequest({
			endpoint: routes.findAndCount.path(params),
			method: getRequestMethodFromHttpMethod(routes.findAndCount.method),
			query,
		}),
	findById: params =>
		fetchRequest({
			endpoint: routes.findById.path(params),
			method: getRequestMethodFromHttpMethod(routes.findById.method),
		}),

	create: (params, body) =>
		fetchRequest({
			body,
			endpoint: routes.create.path(params),
			method: getRequestMethodFromHttpMethod(routes.create.method),
		}),
	delete: params =>
		fetchRequest({
			endpoint: routes.delete.path(params),
			method: getRequestMethodFromHttpMethod(routes.delete.method),
		}),
	update: (params, body) =>
		fetchRequest({
			body,
			endpoint: routes.update.path(params),
			method: getRequestMethodFromHttpMethod(routes.update.method),
		}),
};
