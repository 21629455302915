import { Model } from "@nna/core";
import * as z from "zod";

import { monthsDurationSchema } from "../common";
import { dateCappedSchema } from "../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { entryModelSchema } from "../entry.model";

export const pillar1KindSchema = z.enum(["NORMAL", "DEFERRED", "ADVANCED"]);
export type Pillar1Kind = z.infer<typeof pillar1KindSchema>;
const discriminationKey = "kind";

const deferredPillar1 = z.object({
	[discriminationKey]: z.literal("DEFERRED"),
	duration: monthsDurationSchema(1, 5),
});
const advancedPillar1 = z.object({
	[discriminationKey]: z.literal("ADVANCED"),
	duration: monthsDurationSchema(1, 2),
});
const normalPillar1 = z.object({
	[discriminationKey]: z.literal("NORMAL"),
});

export const pillar1IncomeSchema = z.discriminatedUnion(discriminationKey, [
	deferredPillar1,
	advancedPillar1,
	normalPillar1,
]);

export const pillar1IndexationKindSchema = z.enum(["INDEXED", "FIXED"]);
export type Pillar1IndexationKind = z.infer<typeof pillar1IndexationKindSchema>;

export const pillar1IndexationRate = z.discriminatedUnion("indexed", [
	z.object({ indexed: z.literal("FIXED") }),
	z.object({
		indexed: z.literal("INDEXED"),
		rate: EntryCommon.boundedPercentageSchema,
	}),
]);

export const pillar1ModelShape = {
	customer: EntryCommon.customerSingleSchema,
	firstContributionYear: dateCappedSchema({}),
	contributionYears: z.number().positive(),
	incomeType: pillar1IncomeSchema,

	avsRevenue: z.object({
		total: EntryCommon.positiveAmountSchema,
		inMarriage: EntryCommon.positiveAmountSchema
			.nullable()
			.or(z.literal("").transform(() => null)), // FORMIK_456: To handle Formik empty string state
		offMarriage: EntryCommon.positiveAmountSchema
			.nullable()
			.or(z.literal("").transform(() => null)), // FORMIK_456: To handle Formik empty string state
	}),

	indexation: pillar1IndexationRate,
} as const satisfies z.ZodRawShape;

export const pillar1ModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(pillar1ModelShape),
		{
			...pillar1ModelShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});

export type Pillar1Model = z.infer<typeof pillar1ModelSchema>;
