import styled from "@emotion/styled";

/** Style-props for {@link Columned} */
export interface ColumnedProps {
	/**
	 * Reverse the view (bottom to top)
	 *
	 * @default false
	 */
	reverse?: boolean;
}

/** Stack (vertically) its the content */
export const Columned = styled.div<ColumnedProps>`
	display: flex;
	flex-direction: ${({ reverse }) => (reverse ? "column-reverse" : "column")};
`;
