import { createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { sourceDtoSchema } from "./source.dto";

/** Validation schema for {@link SourceQueryResultsDto} */
export const sourceQueryResultsDtoSchema =
	createQueryResultsSchema(sourceDtoSchema);
/** Results for `sources` from a query */
export type SourceQueryResultsDto = z.infer<typeof sourceQueryResultsDtoSchema>;
