import { createQueryObjectSchema } from "@nna/core";
import * as z from "zod";

import { movementDtoSchema } from "./movement.dto";

/** Validation schema for {@link MovementQueryDto} */
export const movementQueryDtoSchema = createQueryObjectSchema(
	movementDtoSchema,
	{ coerce: true, strict: true },
).strict();
/** DTO used to filter `movement` */
export type MovementQueryDto = z.infer<typeof movementQueryDtoSchema>;
