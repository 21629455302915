import * as z from "zod";

import { sourceDtoSchema } from "../../source";
import { MovementModel, movementModelSchema } from "../movement.model";

/** Validation schema for {@link MovementDto} */
export const movementDtoSchema = z.object({
	...movementModelSchema.shape,
	from: sourceDtoSchema,
	to: sourceDtoSchema,
} satisfies Record<keyof MovementModel, z.ZodTypeAny>);
/** Default Dto for an `Movement */
export type MovementDto = z.infer<typeof movementDtoSchema>;
