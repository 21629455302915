import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { entryModelSchema } from "../entry.model";
import { childModelDTOSchema } from "../personal/child";

/** Validation schema for {@link AccountType} */
export const accountTypeSchema = z.enum([
	"PRIVATE",
	"SAVINGS",
	"CURRENT",
	"RENT_GUARANTEE",
	// A bit special, it can be the initial source for everything
	"EXTERNAL",
]);
export type AccountType = z.infer<typeof accountTypeSchema>;
/** Default value for {@link AccountType} in {@link AccountModel} */
export const ACCOUNT_TYPE_DEFAULT = "PRIVATE" as const satisfies AccountType;

/** Zod Shape for {@link accountModelSchema} */
export const accountModelRawShape = {
	fkChild: childModelDTOSchema.shape._id
		.nullable()
		.describe("The child, if any, this account is linked to"),

	entitled: EntryCommon.customerSchema.describe(
		"The customer(s) that possess this account",
	),
	identifier: z.string().describe("Generally the (bank) account number"),
	name: z.string().min(2).describe("Name of the account/establishment"),
	type: accountTypeSchema,

	// Behavior of the following slightly change depending on the date.
	amountInitial: EntryCommon.amountSchema
		.nullable()
		.or(z.literal("").transform(() => null)) // FORMIK_456: To handle Formik empty string state
		.describe("Initial amount, unused when 'future'"),
	date: dateCappedSchema({}).describe(
		"Date when this account open(ed).\n" +
			"When the date is future to the plan, it is of type 'future'",
	),
	expense: EntryCommon.amountSchema
		.nullable()
		.or(z.literal("").transform(() => null)), // FORMIK_456: To handle Formik empty string state
	interest: EntryCommon.percentageSchema
		.nullable()
		.or(z.literal("").transform(() => null)), // FORMIK_456: To handle Formik empty string state
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link AccountModel} */
export const accountModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(accountModelRawShape),
		{
			...accountModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});
/** An account of an `Entry` */
export type AccountModel = z.infer<typeof accountModelSchema>;
