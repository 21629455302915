import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../source-amount/source-amount.actions";
import { accountClosureModelSchema } from "../account-closure.model";

/** Validation schema for {@link AccountClosureCreateDto} */
export const accountClosureCreateDtoSchema = z.object({
	date: accountClosureModelSchema.shape.date,
	funds: z.lazy(() => sourceAmountActionsSchema),
});
export type AccountClosureCreateDto = z.infer<
	typeof accountClosureCreateDtoSchema
>;

/** Validation schema for {@link AccountClosureUpdateDto} */
export const accountClosureUpdateDtoSchema =
	accountClosureCreateDtoSchema.partial();
export type AccountClosureUpdateDto = z.infer<
	typeof accountClosureUpdateDtoSchema
>;
