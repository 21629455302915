import { FieldArray, useFormikContext } from "formik";
import { ReactNode } from "react";

import { ColumnModel, MultilineFormRow } from "./MultilineFormRow";
import { TableCustom } from "../../../ui-atoms/components/TableCustom";
import { SectionSubtitle } from "../../../ui-layout/content-layout";
import { ButtonIcon, ButtonIconSizes } from "../Button/ButtonIcon";

/** Multiline local state management */
export type MultilineRowState = "ADD" | "DELETE" | "NONE" | "UPDATE";

/** Represent the Formik state of a row in our Multiline form */
// TODO: infer from common/multiline.ts schema generator
export interface FormikMultilineRow<T> {
	/** ID of the Multiline row item (only here for UPDATE and NONE states) */
	_id?: number;
	/** Actual state of the Multiline row */
	state: MultilineRowState;
	/** Value of the Multiline row */
	value?: T;
}

/** Represent the Formik state of our Multiline form */
export type FormikMultilineState<T, K extends string> = Record<
	K,
	Array<FormikMultilineRow<T>>
>;

/** Props for a {@link MultilineForm} */
export interface MultilineFormProps<T, K> {
	/** Column definition */
	columns: Array<ColumnModel<T>>;
	/** A custom component that will be appended after the {@link MultilineFormRow} */
	endrow?: ReactNode;
	/** The key at which multiline form values are listed */
	formPrefix: K;
	/** Initial data when we create a new row */
	initialData: () => Partial<T>;
	/** Multiline section title */
	title: string;
}

/**
 * Generic multiline form model
 * It manages the multiline form state through a formik context that has to be declared by a parent.
 */
export function MultilineForm<T, K extends string>(
	props: MultilineFormProps<T, K>,
) {
	const { columns, formPrefix, initialData, title, ...divProps } = props;

	const { values } = useFormikContext<FormikMultilineState<T, K>>();

	return (
		<>
			<FieldArray name={formPrefix}>
				{/* eslint-disable-next-line @typescript-eslint/unbound-method -- can't find easy way to solve */}
				{({ push, remove }) => (
					<div data-testid="form/multiline" {...divProps}>
						<SectionSubtitle
							actions={
								<ButtonIcon
									data-testid="form/multiline/header/add"
									name="add"
									onClick={() => {
										push({
											state: "ADD",
											value: initialData(),
										});
									}}
									size={ButtonIconSizes.SIZE32}
								/>
							}
							title={title}
						/>
						<TableCustom
							$hover
							$multilineLayout
							$stripe
							data-testid="form/multiline/table"
						>
							<thead />
							<tbody>
								{(values[formPrefix] ?? []).map(
									({ _id }, index: number) => (
										<MultilineFormRow
											data-testid={`form/multiline/rows/${_id ? `id-${_id}` : index}`}
											key={index}
											columns={columns}
											formPrefix={formPrefix}
											index={index}
											remove={remove}
										/>
									),
								)}
								{props.endrow}
							</tbody>
						</TableCustom>
					</div>
				)}
			</FieldArray>
		</>
	);
}
