import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	interruptionModelRawShape,
	interruptionModelSchema,
} from "../interruption.model";

/** Validation schema for {@link InterruptionDto} */
export const interruptionDtoSchema = interruptionModelSchema;
export type InterruptionDto = z.infer<typeof interruptionDtoSchema>;

/** Validation schema for {@link InterruptionCreateDto} */
export const interruptionCreateDtoSchema = z
	.object(interruptionModelRawShape)
	.strict();
/** Dto to create a `recipe */
export type InterruptionCreateDto = z.infer<typeof interruptionCreateDtoSchema>;

/** Validation schema for {@link InterruptionQueryDto} */
export const interruptionQueryDtoSchema = createQueryObjectSchema(
	interruptionModelSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `interruption` */
export type InterruptionQueryDto = z.infer<typeof interruptionQueryDtoSchema>;

/** Validation schema for {@link InterruptionQueryResultsDto} */
export const interruptionQueryResultsDtoSchema = createQueryResultsSchema(
	interruptionDtoSchema,
);
/** Results for `interruptions` from a query */
export type InterruptionQueryResultsDto = z.infer<
	typeof interruptionQueryResultsDtoSchema
>;

/** Validation schema for {@link InterruptionUpdateDto} */
export const interruptionUpdateDtoSchema = z
	.object(interruptionModelRawShape)
	.strict()
	.partial();
/** Dto to update a `interruption */
export type InterruptionUpdateDto = z.infer<typeof interruptionUpdateDtoSchema>;
