import { useField, useFormikContext } from "formik";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";
import { Entry } from "~/common";

import { CellInputs, Inputs } from "..";
import { FocusInputProps } from "./FocusInput";
import { useFormatters } from "../../../../utils/formatters";
import { FormTable, Tooltips } from "../../../ui-atoms/components";
import { Layout } from "../../../ui-layout";
import { FormikSwitch } from "../Formik/FormikSwitch";

/** @internal */
function DateRangeField(props: PeriodDate2CellFieldProps) {
	// TODO: this could be extract and be a single component
	const { disabled, name, unfocusedError } = props;

	const { t } = useTranslation();
	const { fDate } = useFormatters();

	const [startField, startMeta] = useField<string>(
		`${name}.${"dateStart" satisfies keyof Entry.Common.PeriodDatePeriodic}`,
	);
	const [endField, endMeta] = useField<string>(
		`${name}.${"dateEnd" satisfies keyof Entry.Common.PeriodDatePeriodic}`,
	);

	// When the form is submitted and the input has not been touched
	//	Case: required input
	const hasBeenSubmitted = 0 < useFormikContext().submitCount;

	// FIXME: better
	interface DateProps {
		autoFocus?: boolean;
		field: typeof endField;
		meta: typeof endMeta;
	}
	const DateField = useCallback(
		({ autoFocus, field, meta }: DateProps) => (
			<Inputs.InlinedError
				{...field}
				autoFocus={autoFocus}
				css={{ flex: 1 }}
				disabled={disabled}
				error={
					(hasBeenSubmitted || meta.touched) && meta.error
						? { title: meta.error }
						: false
				}
				type="date-iso"
				value={field.value || (null as never)}
				sx={Inputs.Styles.inputCell}
			/>
		),
		[disabled, hasBeenSubmitted],
	);

	const unfocusedValue = useMemo(() => {
		if (!startField.value && !endField.value) {
			return false;
		}

		return (
			<span>
				{startField.value ? (
					fDate(startField.value)
				) : (
					<i>{t("common.date.relative.since")}</i>
				)}
				-
				{endField.value ? (
					fDate(endField.value)
				) : (
					<i>{t("common.date.relative.until")}</i>
				)}
			</span>
		);
	}, [fDate, t, startField.value, endField.value]);

	const error = useMemo(() => {
		if (!unfocusedError) {
			return false;
		}

		const metas = [startMeta, endMeta] as const;
		return (
			// Form submitted or one of the field touched
			(hasBeenSubmitted || metas.some(({ touched }) => touched)) &&
			// Has any error
			metas.find(({ error }) => error)?.error
		);
	}, [hasBeenSubmitted, unfocusedError, startMeta, endMeta]);

	return (
		<FormTable.FocusableTableCell
			data-testid="form/cell/period-date/periodic"
			whenFocused={
				<Layout.Lined css={{ height: "100%" }}>
					<DateField
						autoFocus
						data-testid="form/cell/period-date/periodic/start"
						field={startField}
						meta={startMeta}
					/>
					<DateField
						data-testid="form/cell/period-date/periodic/end"
						field={endField}
						meta={endMeta}
					/>
				</Layout.Lined>
			}
		>
			<FormTable.FormCellInnerWrapper>
				<FormTable.UnfocusedGenericValue
					disabled={disabled}
					placeholder={t("entity.entry.evolution.date")}
				>
					{unfocusedValue}
				</FormTable.UnfocusedGenericValue>

				{!!error && <Tooltips.Error title={error} />}
			</FormTable.FormCellInnerWrapper>
		</FormTable.FocusableTableCell>
	);
}

/** Props for {@link SelectField} */
export interface PeriodDate2CellFieldProps
	extends Pick<FocusInputProps, "disabled" | "unfocusedError"> {
	/**
	 * Name of the input
	 * It must be the property that has a {@link Entry.Common.PeriodDate}
	 */
	name: string;
}
/**
 * Places 2 cells in to manage a {@link Entry.Common.PeriodDate}
 * The first cell is a switch for the mode and the second is to set the date
 */
export function PeriodDate2CellField(props: PeriodDate2CellFieldProps) {
	const { t } = useTranslation();
	const { disabled = false, name, unfocusedError = true } = props;

	const [{ value: periodic }] = useField<boolean>(
		`${name}.${"periodic" satisfies keyof Entry.Common.PeriodDate}`,
	);

	return (
		<>
			<FormTable.Cell data-testid="form/cell/period-date/mode" key={name}>
				<FormikSwitch
					name={`${name}.periodic`}
					endDecorator={t("common.periodic")}
					disabled={disabled}
				/>
			</FormTable.Cell>

			{/* TODO: this could be extracted and be a single component (so the "date" part of the data could be more easily customized) */}
			{periodic ? (
				<DateRangeField
					disabled={disabled}
					name={name}
					unfocusedError={unfocusedError}
				/>
			) : (
				<CellInputs.FieldInput
					data-testid="form/cell/period-date/date"
					disabled={disabled}
					name={`${name}.${"date" satisfies keyof Entry.Common.PeriodDateEvent}`}
					placeholder={t("entity.entry.loan.date")}
					type="date-iso"
					unfocusedError={unfocusedError}
				/>
			)}
		</>
	);
}
