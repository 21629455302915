import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { createMultilineSchema } from "../../../common/multiline";
import { childModelDTOSchema, childModelRawShape } from "../child.model";
import {
	interruptionCreateDtoSchema,
	interruptionModelSchema,
	interruptionUpdateDtoSchema,
} from "../interruption";

/** Validation schema for {@link ChildDto} */
export const childDtoSchema = z.object({
	...childModelDTOSchema.shape,
	interruptions: z.array(interruptionModelSchema),
});
export type ChildDto = z.infer<typeof childDtoSchema>;

/** Validation schema for {@link ChildCreateDto} */
export const childCreateDtoSchema = z
	.object({
		...childModelRawShape,
		interruptions: createMultilineSchema(
			interruptionCreateDtoSchema,
			interruptionUpdateDtoSchema,
		),
	})
	.strict();
/** Dto to create a `recipe */
export type ChildCreateDto = z.infer<typeof childCreateDtoSchema>;

/** Validation schema for {@link ChildQueryDto} */
export const childQueryDtoSchema = createQueryObjectSchema(
	childModelDTOSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `child` */
export type ChildQueryDto = z.infer<typeof childQueryDtoSchema>;

/** Validation schema for {@link ChildQueryResultsDto} */
export const childQueryResultsDtoSchema =
	createQueryResultsSchema(childDtoSchema);
/** Results for `childs` from a query */
export type ChildQueryResultsDto = z.infer<typeof childQueryResultsDtoSchema>;

/** Validation schema for {@link ChildUpdateDto} */
export const childUpdateDtoSchema = childCreateDtoSchema.partial();
/** Dto to update a `child */
export type ChildUpdateDto = z.infer<typeof childUpdateDtoSchema>;
