import * as z from "zod";

import { SourceAmountGroup } from "./source-amount.group";
import { sourceAmountModelSchema } from "./source-amount.model";

/** Validation schema for {@link SourceAmountFullModel} */
export const sourceAmountFullModelSchema = z.object({
	...sourceAmountModelSchema.shape,
	group: z.lazy(() => SourceAmountGroup.modelSchema),
});
/**
 * A 'source-amount' is the relation between a `source` with an absolute amount and a `group`.
 * From the `group`, it can deduced multiples movement.
 * ex: the `group` has 1 `source`, but it is configured to be used during 3 years
 * 	-> it "generates" 3 "virtual" movements for the 3 given years.
 *
 * This is the complete model with the group model.
 * Privilege the "simple" Model to:
 * - Avoid circular dependency
 * - The movement-groups generally abstracts themselves in their endpoints
 *
 * Keep this for the backend specification, common knowledge and possibly later a full endpoint to list all 'source-amounts'
 */
export type SourceAmountFullModel = z.infer<typeof sourceAmountFullModelSchema>;
