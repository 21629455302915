import { HttpRoute } from "@nna/core";

import type {
	CreditInterestCreateDto,
	CreditInterestDto,
	CreditInterestQueryDto,
	CreditInterestQueryResultsDto,
	CreditInterestUpdateDto,
} from "./dtos";
import { type EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `CreditInterest` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateCreditInterestHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "interestId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: CreditInterestQueryDto,
					) => Promise<CreditInterestQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<CreditInterestDto>>(),

			create: base.post<
				(body: CreditInterestCreateDto) => Promise<CreditInterestDto>
			>(),
			delete: baseById.delete<() => Promise<CreditInterestDto>>(),
			update: baseById.patch<
				(body: CreditInterestUpdateDto) => Promise<CreditInterestDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/CreditInterest` features and children */
export type CreditInterestHttpConfig =
	EntryHttpConfig["children"]["credits"]["children"]["interests"];
/** HTTP specification for the `Entry/CreditInterest` features */
export type CreditInterestHttp = HttpRoute.Handlers<
	CreditInterestHttpConfig["routes"]
>;
