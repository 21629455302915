import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { pillar1ModelSchema, pillar1ModelShape } from "./pillar1.model";

export const pillar1Dto = pillar1ModelSchema;
export type Pillar1Dto = z.infer<typeof pillar1Dto>;

export const pillar1CreateDto = z.object(pillar1ModelShape);
export type Pillar1CreateDto = z.infer<typeof pillar1CreateDto>;

export const pillar1UpdateDto = pillar1CreateDto.partial();
export type Pillar1UpdateDto = z.infer<typeof pillar1UpdateDto>;

export const pillar1QueryDto = createQueryObjectSchema(pillar1Dto, {
	coerce: true,
	strict: true,
}).strict();
export type Pillar1QueryDto = z.infer<typeof pillar1QueryDto>;

export const pillar1QueryResultsDto = createQueryResultsSchema(pillar1Dto);
export type Pillar1QueryResultsDto = z.infer<typeof pillar1QueryResultsDto>;
