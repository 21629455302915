import { Model } from "@nna/core";
import * as z from "zod";

import { createPeopleNameSchema } from "../people";

/** Validation schema for {@link UserModel} */
export const userModelSchema = z.object({
	...Model.schema.shape,
	email: z.string().describe("Email (and identifier) of a user").email(),
	name: createPeopleNameSchema({ min: { first: 2, last: 0 } }),
});
export type UserModel = z.infer<typeof userModelSchema>;
