import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../entry.change-state";
import { currentModelSchema } from "../current.model";

/** Zod Shape for {@link currentExpenseModelSchema} */
export const currentExpenseModelRawShape = {
	amountParam: z.number().describe("Amount for the expense"),
	label: z.string().min(1),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link ExpenseModel} */
export const currentExpenseModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(currentExpenseModelRawShape),
		{
			...currentExpenseModelRawShape,
			fkCurrent: currentModelSchema.shape._id,
		},
	),
});

export type CurrentExpenseModel = z.infer<typeof currentExpenseModelSchema>;
