import { HttpRoute } from "@nna/core";

import { type EntryHttpConfig } from "../entry.http";
import type {
	MovementCreateDto,
	MovementDto,
	MovementQueryDto,
	MovementQueryResultsDto,
	MovementUpdateDto,
} from "./dtos";

/**
 * Create routes simple definitions for `Movement` features.
 * It only adds the `findAndCount` functionality.
 *
 * !! If there is a real need !! Otherwise simply use the general `movement` endpoint
 * To be used for children of 'movement sources',
 * meaning all the movements that has the source in `from` or `to`.
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createMovementHttpSimpleDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: MovementQueryDto,
					) => Promise<MovementQueryResultsDto>
				>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/**
 * Create routes definitions for `Movement` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createMovementHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const {
		routes: { findAndCount },
	} = createMovementHttpSimpleDefinitions(base);

	const baseById = base.addSegment({
		param: "movementId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount,
			findById: baseById.get<() => Promise<MovementDto>>(),

			create: base.post<
				(body: MovementCreateDto) => Promise<MovementDto>
			>(),
			delete: baseById.delete<() => Promise<MovementDto>>(),
			update: baseById.patch<
				(body: MovementUpdateDto) => Promise<MovementDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Movement` features and children */
export type MovementHttpConfig = EntryHttpConfig["children"]["movements"];
/** HTTP specification for the `Entry/Movement` features */
export type MovementHttp = HttpRoute.Handlers<MovementHttpConfig["routes"]>;
