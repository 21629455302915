import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../entry.change-state";
import { childModelDTOSchema } from "../child.model";

/** Zod Shape for {@link childModelSchema} */
export const interruptionModelRawShape = {
	interruptionPeriodFrom: dateCappedSchema({}),
	interruptionPeriodTo: dateCappedSchema({}),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link InterruptionModel} */
export const interruptionModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(interruptionModelRawShape),
		{
			...interruptionModelRawShape,
			fkChild: childModelDTOSchema.shape._id,
		},
	),
});

export type InterruptionModel = z.infer<typeof interruptionModelSchema>;
