import { HttpRoute } from "@nna/core";

import type {
	StockMarketCreateDto,
	StockMarketDto,
	StockMarketQueryDto,
	StockMarketQueryResultsDto,
	StockMarketUpdateDto,
} from "./dtos";
import { type EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `StockMarket` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateStockMarketHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "marketId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: StockMarketQueryDto,
					) => Promise<StockMarketQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<StockMarketDto>>(),

			create: base.post<
				(body: StockMarketCreateDto) => Promise<StockMarketDto>
			>(),
			delete: baseById.delete<() => Promise<StockMarketDto>>(),
			update: baseById.patch<
				(body: StockMarketUpdateDto) => Promise<StockMarketDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/StockMarket` features and children */
export type StockMarketHttpConfig =
	EntryHttpConfig["children"]["stocks"]["children"]["markets"];
/** HTTP specification for the `Entry/StockMarket` features */
export type StockMarketHttp = HttpRoute.Handlers<
	StockMarketHttpConfig["routes"]
>;
