import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { entryModelSchema } from "../entry.model";

export const typeOfLoanSchema = z.enum([
	"MISC_LOANS",
	"CHECKING_ACCOUNT",
	"FISCAL_DEBT",
]);
export type TypeOfLoan = z.infer<typeof typeOfLoanSchema>;
/** Zod Shape for {@link loanModelSchema} */
export const loanModelRawShape = {
	creditor: EntryCommon.customerSchema,

	debtor: z.string().nullable(),
	// TODO: Templating: Add the specific field of the model
	amount: z.number(),
	date: dateCappedSchema({}).nullable(),
	type: typeOfLoanSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link LoanModel} */
export const loanModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(loanModelRawShape),
		{
			...loanModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});

export type LoanModel = z.infer<typeof loanModelSchema>;
