import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../source-amount/source-amount.actions";
import { stockClosureModelSchema } from "../stock-closure.model";

/** Validation schema for {@link StockClosureCreateDto} */
export const stockClosureCreateDtoSchema = z.object({
	date: stockClosureModelSchema.shape.date,
	funds: sourceAmountActionsSchema,
});
export type StockClosureCreateDto = z.infer<typeof stockClosureCreateDtoSchema>;

/** Validation schema for {@link StockClosureUpdateDto} */
export const stockClosureUpdateDtoSchema =
	stockClosureCreateDtoSchema.partial();
export type StockClosureUpdateDto = z.infer<typeof stockClosureUpdateDtoSchema>;
