import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../source-amount/source-amount.actions";
import { sourceAmountDtoSchema } from "../../../source-amount/source-amount.model";
import {
	creditEvolutionModelRawShape,
	creditEvolutionModelSchema,
} from "../credit.evolution.model";

/** Validation schema for {@link CreditEvolutionDto} */
export const creditEvolutionDtoSchema = z.object({
	...creditEvolutionModelSchema.shape,
	funds: z.array(z.lazy(() => sourceAmountDtoSchema)),
});
export type CreditEvolutionDto = z.infer<typeof creditEvolutionDtoSchema>;

/** Validation schema for {@link CreditEvolutionCreateDto} */
export const creditEvolutionCreateDtoSchema = z.object({
	...creditEvolutionModelRawShape,
	funds: sourceAmountActionsSchema,
});
/** Dto to create a `credit */
export type CreditEvolutionCreateDto = z.infer<
	typeof creditEvolutionCreateDtoSchema
>;

/** Validation schema for {@link CreditEvolutionUpdateDto} */
export const creditEvolutionUpdateDtoSchema =
	creditEvolutionCreateDtoSchema.partial();
/** Dto to update a `credit */
export type CreditEvolutionUpdateDto = z.infer<
	typeof creditEvolutionUpdateDtoSchema
>;

/** Validation schema for {@link CreditEvolutionQueryDto} */
export const creditEvolutionQueryDtoSchema = createQueryObjectSchema(
	creditEvolutionDtoSchema,
	{ coerce: true, strict: true },
).strict();

/** DTO used to filter `creditEvolution` */
export type CreditEvolutionQueryDto = z.infer<
	typeof creditEvolutionQueryDtoSchema
>;

/** Validation schema for {@link CreditEvolutionQueryResultsDto} */
export const creditEvolutionQueryResultsDtoSchema = createQueryResultsSchema(
	creditEvolutionDtoSchema,
);
/** Results for `credits` from a query */
export type CreditEvolutionQueryResultsDto = z.infer<
	typeof creditEvolutionQueryResultsDtoSchema
>;
