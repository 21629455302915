import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../entry.change-state";
import { EntryCommon } from "../entry.common";
import { entryModelSchema } from "../entry.model";

export const typeOfCreditSchema = z.enum([
	"MISC_CREDITS",
	"CHECKING_ACCOUNT",
	"FISCAL_DEBT",
]);
export type TypeOfCredit = z.infer<typeof typeOfCreditSchema>;
/** Zod Shape for {@link creditModelSchema} */
export const creditModelRawShape = {
	creditor: z.string().nullable(),

	debtor: EntryCommon.customerSchema,
	// TODO: Templating: Add the specific field of the model
	amount: z.number(),
	date: dateCappedSchema({}).nullable(),
	type: typeOfCreditSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link CreditModel} */
export const creditModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(creditModelRawShape),
		{
			...creditModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
});

export type CreditModel = z.infer<typeof creditModelSchema>;
