import * as dateFns from "date-fns";
import { TFunction, useTranslation } from "next-i18next";

import { birthday } from "./date";

export type FormatCurrencyOptions = Pick<
	Intl.NumberFormatOptions,
	"maximumFractionDigits" | "minimumFractionDigits"
>;
function formatCurrency(
	locale: string,
	number: number,
	options: FormatCurrencyOptions = {},
): string {
	return number.toLocaleString(locale, {
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
		...options,
	});
}

type fCurrencyWithUnit = (
	number: number,
	options?: FormatCurrencyOptions,
	unit?: string,
) => string;

/**
 * Formats a date
 *
 * @param t translation function
 * @param date date-like to format
 * @returns the date formatted
 */
export function formatDate(t: TFunction, date: dateFns.DateArg<Date>) {
	return dateFns.format(date, t("common.format.date"));
}

/**
 * Hook returning formatters depending on the current translation.
 *
 * @example ```tsx
 * const { fDate } = useFormatters();
 * const now = new Date();
 * return <span>{fDate(now)}</span>;
 * ```
 *
 * @returns an object with different formatter functions
 */
export function useFormatters() {
	const { t } = useTranslation();

	const fCurrency = formatCurrency.bind(
		formatCurrency,
		t("common.format.locale-currency"),
	);
	const fCurrencyWithUnit: fCurrencyWithUnit = (number, options, unit) =>
		`${fCurrency(number, options)} ${unit ?? t("common.currency.chf")}`;

	const fBirthday = (birthdayDate: Date, withAge: boolean) =>
		birthday(t, { birthdayDate, withAge });

	return {
		/** Formats a date in a birthday format (where you can include or not the age) */
		fBirthday,
		/** Formats a currency number (the currency unit is not included). */
		fCurrency,
		/** Formats a currency number (with the currency unit). */
		fCurrencyWithUnit,
		/**
		 * Formats a date
		 *
		 * @param date date-like to format
		 * @returns the date formatted
		 */
		fDate: (date: dateFns.DateArg<Date>) => formatDate(t, date),
	};
}
/** The formatters output from {@link useFormatters} */
export type Formatters = ReturnType<typeof useFormatters>;
