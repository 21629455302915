import { HttpRoute } from "@nna/core";

import { type EntryHttpConfig } from "../entry.http";
import { SourceQueryDto, SourceQueryResultsDto } from "./dtos";

/**
 * Create routes definitions for `Source` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createSourceHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(query: SourceQueryDto) => Promise<SourceQueryResultsDto>
				>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Recipe` features and children */
export type SourceHttpConfig = EntryHttpConfig["children"]["sources"];
/** HTTP specification for the `Entry/Recipe` features */
export type SourceHttp = HttpRoute.Handlers<SourceHttpConfig["routes"]>;
