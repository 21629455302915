import * as z from "zod";

import { entryDtoSchema } from "./entry.dto";

export const stepSchema = z.number().gte(0);

/** Validation schema for {@link EntryFindOneDto} */
export const entryFindOneDtoSchema = z.object({
	...entryDtoSchema.shape,
	/** Summary (count relations) of the entry */
	steps: z.object({
		// TODO: a nested object for each section? Set prefix?

		// 'information' section
		information: stepSchema.describe(
			"Sum of the 3 types of data: personal information, residence(s) and kids",
		),

		// 'budget' section
		expenseCurrent: stepSchema,
		expenseExceptional: stepSchema,
		recipes: stepSchema,

		// 'credits/loan' section
		credits: stepSchema,
		loans: stepSchema,

		// 'furniture' ("mobilier") section
		accounts: stepSchema,
		deposits: stepSchema,
		shares: stepSchema,
		transferables: stepSchema,

		// 'real estate' section
		buildings: stepSchema,
		mortgages: stepSchema,

		// 'PILLAR 1st' section
		pillar1: stepSchema,

		// 'PILLAR 2nd' section
		// TODO

		// 'PILLAR 3rd' section
		pillar3A: stepSchema,
		pillar3B: stepSchema,

		// 'independent activity/self employment' section
		// TODO
	}),

	// TODO: simply return the relations ?
});
export type EntryFindOneDto = z.infer<typeof entryFindOneDtoSchema>;
