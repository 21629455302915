import * as z from "zod";

import { creditEvolutionModelSchema } from "../../../credit/evolution/credit.evolution.model";
import { creditEvolutionDtoSchema } from "../../../credit/evolution/dtos/credit.evolution.dto";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "credit-evolution";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkCreditEvolution: creditEvolutionModelSchema.shape._id,
});
/** Movement Group representation of a {@link creditEvolutionModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	creditEvolution: z.lazy(() => creditEvolutionDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
