import * as z from "zod";

import { EntryCommon } from "../../entry.common";
import { accountModelSchema } from "../account.model";

/** Validation schema for {@link AccountClosureModel} */
export const accountClosureModelSchema = EntryCommon.createClosureModelSchema(
	{},
	{ fkAccount: accountModelSchema.shape._id },
);
export type AccountClosureModel = z.infer<typeof accountClosureModelSchema>;
