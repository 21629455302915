import { HttpRoute } from "@nna/core";

import type {
	LoanInterestCreateDto,
	LoanInterestDto,
	LoanInterestQueryDto,
	LoanInterestQueryResultsDto,
	LoanInterestUpdateDto,
} from "./dtos";
import { type EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `LoanInterest` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateLoanInterestHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "interestId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: LoanInterestQueryDto,
					) => Promise<LoanInterestQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<LoanInterestDto>>(),

			create: base.post<
				(body: LoanInterestCreateDto) => Promise<LoanInterestDto>
			>(),
			delete: baseById.delete<() => Promise<LoanInterestDto>>(),
			update: baseById.patch<
				(body: LoanInterestUpdateDto) => Promise<LoanInterestDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/LoanInterest` features and children */
export type LoanInterestHttpConfig =
	EntryHttpConfig["children"]["loans"]["children"]["interests"];
/** HTTP specification for the `Entry/LoanInterest` features */
export type LoanInterestHttp = HttpRoute.Handlers<
	LoanInterestHttpConfig["routes"]
>;
