import { Entry } from "~/common";

import { fetchRequest } from "../../utils/utils";
import { getRequestMethodFromHttpMethod } from "../utils";

const { routes } = Entry.ENTRY_HTTP_CONFIG.children.sources;

/** Http client for `Entry/Source` features */
export const SourceHttpClient: Entry.SourceHttp = {
	findAndCount: (params, query) =>
		fetchRequest({
			endpoint: routes.findAndCount.path(params),
			method: getRequestMethodFromHttpMethod(routes.findAndCount.method),
			query,
		}),
};
