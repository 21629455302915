import { HttpRoute } from "@nna/core";

import type {
	TransferableClosureCreateDto,
	TransferableClosureDto,
	TransferableClosureUpdateDto,
} from "./dtos";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `TransferableClosure` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateTransferableClosureHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "closureId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			create: base.post<
				(
					body: TransferableClosureCreateDto,
				) => Promise<TransferableClosureDto>
			>(),
			delete: baseById.delete<() => Promise<TransferableClosureDto>>(),
			update: baseById.patch<
				(
					body: TransferableClosureUpdateDto,
				) => Promise<TransferableClosureDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/TransferableClosure` features and children */
export type TransferableClosureHttpConfig =
	EntryHttpConfig["children"]["transferables"]["children"]["closures"];
/** HTTP specification for the `Entry/TransferableClosure` features */
export type TransferableClosureHttp = HttpRoute.Handlers<
	TransferableClosureHttpConfig["routes"]
>;
