import * as dateFns from "date-fns";

import { PlanDto } from "../../../plan/dtos";
import * as Utils from "../../utils";
import { RecipeDto } from "../dtos";
import { SalaryUtils } from "../salary";

/**
 * Indicate if the recipe is future or not, based on the most recent salary
 *
 * @param plan the plan (to get the year)
 * @param recipe the recipe (to get the salaries)
 * @returns true if the recipe is future, false otherwise
 */
export function isFuture(plan: PlanDto, recipe: RecipeDto) {
	const salary = SalaryUtils.getCloserToDate(recipe.salaries);
	if (salary === null) {
		return false;
	}

	return dateFns.isAfter(salary.dateStart, Utils.date.fromYear(plan.year));
}
