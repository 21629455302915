import * as z from "zod";

import { schema as successSchema } from "./auth.success.dto";
import { userDtoSchema } from "../../user/dtos";

/** Validation schema for {@link Dto} */
export const schema = z.object({
	expireOn: successSchema.shape.expireOn,
	issuedAt: successSchema.shape.issuedAt,
	user: userDtoSchema,
});

export type Dto = z.infer<typeof schema>;
