import { Entry } from "~/common";

import { EntryHttpClients } from "../../../clients";
import { RepositoryFromHttp } from "../../common";

// const { routes } = Entry.ENTRY_HTTP_CONFIG.children.loans.children.closures;
const httpClient = EntryHttpClients.LoanClosureHttpClient;

/** Repository options of 'entry/loan/closures' HTTP client to use with 'react-query' */
export const LoanClosureRepository = {
	create: { mutationFn: ([params, body]) => httpClient.create(params, body) },
	delete: { mutationFn: ([params]) => httpClient.delete(params) },
	update: { mutationFn: ([params, body]) => httpClient.update(params, body) },
} as const satisfies RepositoryFromHttp<Entry.LoanClosureHttp>;
