import * as joy from "@mui/joy";
import type { SxProps } from "@mui/joy/styles/types";

import { theme } from "../../../ui-layout/styles/theme";

/**
 * Styles overrides when we want to display an {@link Select} inside a form table cell
 * In Joy SX format
 */
export const selectCellSX = {
	background: "none",
	borderRadius: "0",
	height: "100%",
	minHeight: "100%",
	outline: "none",
	paddingLeft: "14px",
	marginTop: "-1px",

	[`&.${joy.selectClasses.expanded}`]: {
		border: `2px solid ${theme.palette.blue.primary}`,
		borderRadius: "0",
		outline: "none",
		paddingLeft: "12px",
	},
} satisfies SxProps;
