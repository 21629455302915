import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { stockModelSchema } from "../stock.model";

/** Validation schema for {@link StockTransactionType} */
export const stockTransactionTypeSchema = z.enum(["INCREASE", "REFUND"]);
export type StockTransactionType = z.infer<typeof stockTransactionTypeSchema>;

/** Zod Shape for {@link stockTransactionModelSchema} */
export const stockTransactionModelRawShape = {
	amount: z
		.number()
		.describe("Amount for this transaction (only for visual)"),
	period: EntryCommon.periodDateSchema(),
	type: stockTransactionTypeSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link StockModel} */
export const stockTransactionModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(stockTransactionModelRawShape),
		{
			...stockTransactionModelRawShape,
			fkStock: stockModelSchema.shape._id,
		},
	),
});
export type StockTransactionModel = z.infer<typeof stockTransactionModelSchema>;
