import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { createMultilineSchema } from "../../../common/multiline";
import { currentModelRawShape, currentModelSchema } from "../current.model";
import {
	currentExpenseCreateDtoSchema,
	currentExpenseDtoSchema,
	currentExpenseUpdateDtoSchema,
} from "../expense";

/** Validation schema for {@link CurrentDto} */
export const currentDtoSchema = z.object({
	...currentModelSchema.shape,
	expenses: z.array(currentExpenseDtoSchema),
});
export type CurrentDto = z.infer<typeof currentDtoSchema>;

/**
 * Validation schema for {@link CurrentCreateDto}
 * It includes the list of expenses in a Multiline format.
 */
export const currentCreateDtoSchema = z
	.object({
		...currentModelRawShape,
		expenses: createMultilineSchema(
			currentExpenseCreateDtoSchema,
			currentExpenseUpdateDtoSchema,
		),
	})
	.strict();
/** Dto to create a `recipe */
export type CurrentCreateDto = z.infer<typeof currentCreateDtoSchema>;

/** Validation schema for {@link CurrentQueryDto} */
export const currentQueryDtoSchema = createQueryObjectSchema(currentDtoSchema, {
	coerce: true,
	strict: true,
}).strict();

/** DTO used to filter `current` */
export type CurrentQueryDto = z.infer<typeof currentQueryDtoSchema>;

/** Validation schema for {@link CurrentQueryResultsDto} */
export const currentQueryResultsDtoSchema =
	createQueryResultsSchema(currentDtoSchema);
/** Results for `currents` from a query */
export type CurrentQueryResultsDto = z.infer<
	typeof currentQueryResultsDtoSchema
>;

/**
 * Validation schema for {@link CurrentUpdateDto}
 * It includes the list of expenses in a Multiline format.
 */
export const currentUpdateDtoSchema = currentCreateDtoSchema.partial();
/** Dto to update a `current */
export type CurrentUpdateDto = z.infer<typeof currentUpdateDtoSchema>;
