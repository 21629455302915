import * as z from "zod";

import { sourceAmountDtoSchema } from "../../../source-amount/source-amount.model";
import { transferableClosureModelSchema } from "../transferable-closure.model";

/** Validation schema for {@link TransferableClosureDto} */
export const transferableClosureDtoSchema = z.object({
	...transferableClosureModelSchema.shape,
	funds: z.array(z.lazy(() => sourceAmountDtoSchema)),
});
export type TransferableClosureDto = z.infer<
	typeof transferableClosureDtoSchema
>;
