import * as z from "zod";

import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "quantity";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	quantity: z.number().int().describe("number of moved items"),
});
/** Movement data for with 'empty' data */
export type Model = z.infer<typeof schema>;
