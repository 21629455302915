import * as z from "zod";

import { movementCreateDtoSchema } from "./movement.create.dto";

// Let's not allow changing the sources of a movement
const { from: _1, to: _2, ...schema } = movementCreateDtoSchema.shape;
/** Validation schema for {@link MovementUpdateDto} */
export const movementUpdateDtoSchema = z.object(schema).partial();
/** Dto to update a `movement` */
export type MovementUpdateDto = z.infer<typeof movementUpdateDtoSchema>;
