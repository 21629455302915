import * as z from "zod";

import { planModelSchema } from "../plan.model";

const baseShape = planModelSchema.shape;
/** Validation schema for {@link PlanCreateDto} */
export const planCreateDtoSchema = z
	.object({
		fkCounselor: baseShape.fkCounselor,
		fkCustomer: baseShape.fkCustomer,
		fkCustomer2: baseShape.fkCustomer2,
		observationTime: baseShape.observationTime,
		year: baseShape.year,
	})
	.strict();
/** DTO to create a plan */
export type PlanCreateDto = z.infer<typeof planCreateDtoSchema>;
