import { Model } from "@nna/core";
import * as z from "zod";

import { dateCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { entryModelSchema } from "../../entry.model";

/** Zod Shape for {@link exceptionalModelSchema} */
export const exceptionalModelRawShape = {
	amountParam: z.number().describe("Amount for the expense"),
	deductible: z.boolean().describe("Is the expense deductible"),
	label: z.string().min(1),
	dateStart: dateCappedSchema({}),
	dateEnd: dateCappedSchema({}),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link ExceptionalModel} */
export const exceptionalModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(exceptionalModelRawShape),
		{
			...exceptionalModelRawShape,

			fkEntry: entryModelSchema.shape._id,
		},
	),
});

export type ExceptionalModel = z.infer<typeof exceptionalModelSchema>;
