import { HttpRoute } from "@nna/core";

import type {
	LoanEvolutionCreateDto,
	LoanEvolutionDto,
	LoanEvolutionQueryDto,
	LoanEvolutionQueryResultsDto,
	LoanEvolutionUpdateDto,
} from "./dtos";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `LoanEvolution` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateLoanEvolutionHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "evolutionId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: LoanEvolutionQueryDto,
					) => Promise<LoanEvolutionQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<LoanEvolutionDto>>(),

			create: base.post<
				(body: LoanEvolutionCreateDto) => Promise<LoanEvolutionDto>
			>(),
			delete: baseById.delete<() => Promise<LoanEvolutionDto>>(),
			update: baseById.patch<
				(body: LoanEvolutionUpdateDto) => Promise<LoanEvolutionDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/LoanEvolution` features and children */
export type LoanEvolutionHttpConfig =
	EntryHttpConfig["children"]["loans"]["children"]["evolutions"];
/** HTTP specification for the `Entry/LoanEvolution` features */
export type LoanEvolutionHttp = HttpRoute.Handlers<
	LoanEvolutionHttpConfig["routes"]
>;
