import * as z from "zod";

import { movementModelSchema } from "../movement/movement.model";
import { sourceSchema } from "../source/source.model";

/** Validation schema for {@link AccountMovementDirection}*/
export const accountMovementDirectionSchema = z
	.enum(["IN", "OUT"])
	.describe("[IN] is `TO` in a movement");
export type AccountMovementDirection = z.infer<
	typeof accountMovementDirectionSchema
>;

/** Schema for {@link AccountMovement} */
export const accountMovementSchema = z.object({
	amount: movementModelSchema.shape.amount,
	date: movementModelSchema.shape.date,
	direction: accountMovementDirectionSchema,
	source: sourceSchema,
});
/** Schema to manipulate movement from or out of an account */
export type AccountMovement = z.infer<typeof accountMovementSchema>;
