import { Model } from "@nna/core";
import * as z from "zod";

import { yearCappedSchema } from "../../common/date";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { entryModelSchema } from "../../entry.model";

/** Zod Shape for {@link currentModelSchema} */
export const currentModelRawShape = {
	year: yearCappedSchema({}).describe("Year for the expense group"),

	livingCostParam:
		EntryCommon.Evolution.createEvolutionSchema(true).describe(
			"Amount or Percent",
		),
	marginParam:
		EntryCommon.Evolution.createEvolutionSchema(true).describe(
			"Amount or Percent",
		),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link CurrentModel} */
export const currentModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(currentModelRawShape),
		{
			...currentModelRawShape,

			fkEntry: entryModelSchema.shape._id,
		},
	),
});

export type CurrentModel = z.infer<typeof currentModelSchema>;
