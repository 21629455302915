import { OmitKnown } from "@nna/core";
import { useField } from "formik";

import { FocusInput, FocusInputProps } from "./FocusInput";
import { DotPath } from "../../../../utils/types/DotPath";
import { useFormikErrorDeepCheck } from "../Formik/useFormikErrorDeepCheck";
import { InputPropsType } from "../inputs/Input";

/** Props for {@link FieldInput} */
export interface FieldInputProps<T extends object | string>
	extends OmitKnown<FocusInputProps, "asHeader" | "input"> {
	/** Override props for the input when focused */
	input?: OmitKnown<
		NonNullable<FocusInputProps["input"]>,
		"error" | "name" | "onChange" | "tabIndex" | "type" | "value"
	>;
	/** Name of the input */
	name: T extends object ? DotPath<T> : T;
	/** Type of the input */
	type?: InputPropsType;
}
/**
 * Wraps {@link FocusInput} in a Formik {@link Field}.
 *
 * Note:
 * - It automatically adds a default `endDecorator` on type `currency`
 *
 * @template T the full object this field validates (preferred) or a simple string
 */
export function FieldInput<T extends object | string>(
	props: FieldInputProps<T>,
) {
	const { input: inputProps = {}, name, type, ...cellProps } = props;

	const [field] = useField(name);

	// Needed to handle required inputs
	const error = useFormikErrorDeepCheck(name);

	return (
		<FocusInput
			{...cellProps}
			input={{
				type,
				...inputProps,

				...field,
				error: error ? { title: error } : false,
			}}
		/>
	);
}
