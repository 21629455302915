import * as z from "zod";

import { sourceAmountDtoSchema } from "../../../source-amount/source-amount.model";
import { accountClosureModelSchema } from "../account-closure.model";

/** Validation schema for {@link AccountClosureDto} */
export const accountClosureDtoSchema = z.object({
	...accountClosureModelSchema.shape,
	funds: z
		.array(z.lazy(() => sourceAmountDtoSchema))
		.describe("Destinations of all account amount when closing"),
});
export type AccountClosureDto = z.infer<typeof accountClosureDtoSchema>;
