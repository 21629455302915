import * as z from "zod";

import { EntryCommon } from "../../entry.common";
import { creditModelSchema } from "../credit.model";

/** Validation schema for {@link CreditClosureModel} */
export const creditClosureModelSchema = EntryCommon.createClosureModelSchema(
	{},
	{ fkCredit: creditModelSchema.shape._id },
);
export type CreditClosureModel = z.infer<typeof creditClosureModelSchema>;
