import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { sourceAmountActionsSchema } from "../../../source-amount/source-amount.actions";
import { sourceAmountDtoSchema } from "../../../source-amount/source-amount.model";
import {
	stockTransactionModelRawShape,
	stockTransactionModelSchema,
} from "../stock.transaction.model";

/** Validation schema for {@link StockTransactionDto} */
export const stockTransactionDtoSchema = z.object({
	...stockTransactionModelSchema.shape,
	funds: z.array(z.lazy(() => sourceAmountDtoSchema)),
});
export type StockTransactionDto = z.infer<typeof stockTransactionDtoSchema>;

/** Validation schema for {@link StockTransactionCreateDto} */
export const stockTransactionCreateDtoSchema = z.object({
	...stockTransactionModelRawShape,
	funds: sourceAmountActionsSchema,
});
/** Dto to create a `stock */
export type StockTransactionCreateDto = z.infer<
	typeof stockTransactionCreateDtoSchema
>;

/** Validation schema for {@link StockTransactionUpdateDto} */
export const stockTransactionUpdateDtoSchema =
	stockTransactionCreateDtoSchema.partial();
/** Dto to update a `stock */
export type StockTransactionUpdateDto = z.infer<
	typeof stockTransactionUpdateDtoSchema
>;

/** Validation schema for {@link StockTransactionQueryDto} */
export const stockTransactionQueryDtoSchema = createQueryObjectSchema(
	stockTransactionDtoSchema,
	{ coerce: true, strict: true },
).strict();

/** DTO used to filter `stockTransaction` */
export type StockTransactionQueryDto = z.infer<
	typeof stockTransactionQueryDtoSchema
>;

/** Validation schema for {@link StockTransactionQueryResultsDto} */
export const stockTransactionQueryResultsDtoSchema = createQueryResultsSchema(
	stockTransactionDtoSchema,
);
/** Results for `stocks` from a query */
export type StockTransactionQueryResultsDto = z.infer<
	typeof stockTransactionQueryResultsDtoSchema
>;
