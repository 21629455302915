import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { createMultilineSchema } from "../../common/multiline";
import { stockClosureDtoSchema } from "../closure/dtos";
import {
	stockDividendCreateDtoSchema,
	stockDividendDtoSchema,
	stockDividendUpdateDtoSchema,
} from "../dividend";
import {
	stockFiscalCreateDtoSchema,
	stockFiscalDtoSchema,
	stockFiscalUpdateDtoSchema,
} from "../fiscal";
import {
	stockMarketCreateDtoSchema,
	stockMarketDtoSchema,
	stockMarketUpdateDtoSchema,
} from "../market";
import { stockModelRawShape, stockModelSchema } from "../stock.model";
import { stockTransactionDtoSchema } from "../transaction/dtos";

/** Validation schema for {@link StockDto} */
export const stockDtoSchema = z.object({
	...stockModelSchema.shape,
	closure: z
		.lazy(() => stockClosureDtoSchema)
		.nullable()
		.describe("The closure of this stock.<br>`null` when there is none"),
	transactions: z.array(z.lazy(() => stockTransactionDtoSchema)),
	dividends: z.array(stockDividendDtoSchema),
	fiscals: z.array(stockFiscalDtoSchema),
	markets: z.array(stockMarketDtoSchema),
});
export type StockDto = z.infer<typeof stockDtoSchema>;

/** Validation schema for {@link StockCreateDto} */
export const stockCreateDtoSchema = z
	.object({
		...stockModelRawShape,
		dividends: createMultilineSchema(
			stockDividendCreateDtoSchema,
			stockDividendUpdateDtoSchema,
		),
		fiscals: createMultilineSchema(
			stockFiscalCreateDtoSchema,
			stockFiscalUpdateDtoSchema,
		),
		markets: createMultilineSchema(
			stockMarketCreateDtoSchema,
			stockMarketUpdateDtoSchema,
		),
	})
	.strict();
/** Dto to create a `stock */
export type StockCreateDto = z.infer<typeof stockCreateDtoSchema>;

/**
 * Validation schema for {@link StockQueryDto}
 * Note that we use 'stockModelSchema' here, because using the 'stockDtoSchema' with interests
 * is causing a ts(2589) issue in the backend payload file.
 */
export const stockQueryDtoSchema = createQueryObjectSchema(stockModelSchema, {
	coerce: true,
	strict: true,
}).strict();

/** DTO used to filter `stock` */
export type StockQueryDto = z.infer<typeof stockQueryDtoSchema>;

/** Validation schema for {@link StockQueryResultsDto} */
export const stockQueryResultsDtoSchema =
	createQueryResultsSchema(stockDtoSchema);
/** Results for `stocks` from a query */
export type StockQueryResultsDto = z.infer<typeof stockQueryResultsDtoSchema>;

/** Validation schema for {@link StockUpdateDto} */
export const stockUpdateDtoSchema = stockCreateDtoSchema.partial();
/** Dto to update a `stock */
export type StockUpdateDto = z.infer<typeof stockUpdateDtoSchema>;
