import { createQueryObjectSchema } from "@nna/core";
import * as z from "zod";

import { sourceDtoSchema } from "./source.dto";

/** Validation schema for {@link SourceQueryDto} */
export const sourceQueryDtoSchema = createQueryObjectSchema(sourceDtoSchema, {
	coerce: true,
	strict: true,
}).strict();
/** DTO used to filter `source` */
export type SourceQueryDto = z.infer<typeof sourceQueryDtoSchema>;
