import * as z from "zod";

import { sourceAmountDtoSchema } from "../../source-amount/source-amount.model";
import { accountModelSchema } from "../account.model";
import { accountClosureDtoSchema } from "../closure";

/** Base schema for {@link accountDtoSchema} with reduced relations */
export const accountBaseDtoSchema = accountModelSchema;

/** Validation schema for {@link AccountDto} */
export const accountDtoSchema = z.object({
	...accountModelSchema.shape,
	closure: z
		.lazy(() => accountClosureDtoSchema)
		.nullable()
		.describe("The closure of this account.<br>`null` when there is none"),
	funds: z
		.array(z.lazy(() => sourceAmountDtoSchema))
		.describe(
			"The 'provenance de fonds'.<br/>Not supposed to be used if the account is 'future'",
		),
});
/** Default Dto for an `account */
export type AccountDto = z.infer<typeof accountDtoSchema>;
