import * as z from "zod";

import { transferableBaseDtoSchema } from "../../transferable/dtos/transferable.dtos";
import { transferableModelSchema } from "../../transferable/transferable.model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "transferable";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkTransferable: transferableModelSchema.shape._id,
});
/** Movement source for an {@link transferableBaseDtoSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	transferable: z.lazy(() => transferableBaseDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
