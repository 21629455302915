import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	stockFiscalModelRawShape,
	stockFiscalModelSchema,
} from "../stock.fiscal.model";

/** Validation schema for {@link StockFiscalDto} */
export const stockFiscalDtoSchema = stockFiscalModelSchema;
export type StockFiscalDto = z.infer<typeof stockFiscalDtoSchema>;

/** Validation schema for {@link StockFiscalCreateDto} */
export const stockFiscalCreateDtoSchema = z.object(stockFiscalModelRawShape);
/** Dto to create a `stock */
export type StockFiscalCreateDto = z.infer<typeof stockFiscalCreateDtoSchema>;

/** Validation schema for {@link StockFiscalQueryDto} */
export const stockFiscalQueryDtoSchema = createQueryObjectSchema(
	stockFiscalDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `stockFiscal` */
export type StockFiscalQueryDto = z.infer<typeof stockFiscalQueryDtoSchema>;

/** Validation schema for {@link StockFiscalQueryResultsDto} */
export const stockFiscalQueryResultsDtoSchema =
	createQueryResultsSchema(stockFiscalDtoSchema);
/** Results for `stocks` from a query */
export type StockFiscalQueryResultsDto = z.infer<
	typeof stockFiscalQueryResultsDtoSchema
>;

/** Validation schema for {@link StockFiscalUpdateDto} */
export const stockFiscalUpdateDtoSchema = stockFiscalCreateDtoSchema.partial();
/** Dto to update a `stock */
export type StockFiscalUpdateDto = z.infer<typeof stockFiscalUpdateDtoSchema>;
