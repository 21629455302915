import { createQueryObjectSchema } from "@nna/core";
import * as z from "zod";

import { accountDtoSchema } from "./account.dto";

/** Validation schema for {@link AccountQueryDto} */
export const accountQueryDtoSchema = createQueryObjectSchema(accountDtoSchema, {
	coerce: true,
	strict: true,
}).strict();
/** DTO used to filter `account` */
export type AccountQueryDto = z.infer<typeof accountQueryDtoSchema>;
