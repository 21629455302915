import * as z from "zod";

import { createMultilineSchema } from "../../common/multiline";
import { movementModelSchema } from "../../movement";
import { Options } from "../../movement/data";
import { transferableModelRawShape } from "../transferable.model";

const shape = {
	amount: movementModelSchema.shape.amount,
	date: movementModelSchema.shape.date,
	data: Options.Quantity.schema,
} as const satisfies z.ZodRawShape;
const transferableMovementCreateSchema = z.object({
	...shape,
	to: movementModelSchema.shape.to,
});
/** 'Create payload' in the 'multiline' of {@link TransferableCreateDto} and {@link TransferableUpdateDto} */
export type TransferableMovementCreate = z.infer<
	typeof transferableMovementCreateSchema
>;

const transferableMovementUpdateSchema = z.object(shape);
/** 'Update payload' in the 'multiline' of {@link TransferableCreateDto} and {@link TransferableUpdateDto} */
export type TransferableMovementUpdate = z.infer<
	typeof transferableMovementUpdateSchema
>;

/**
 * Validation schema for {@link TransferableCreateDto}.
 * Moved to another file to avoid circular dependencies
 */
export const transferableCreateDtoSchema = z
	.object({
		...transferableModelRawShape,

		// Not full movements
		movements: createMultilineSchema(
			transferableMovementCreateSchema,
			transferableMovementUpdateSchema.partial(),
		).default([]),
	})
	.strict();

/** Dto to create a `transferable */
export type TransferableCreateDto = z.infer<typeof transferableCreateDtoSchema>;

/** Validation schema for {@link TransferableUpdateDto} */
export const transferableUpdateDtoSchema =
	transferableCreateDtoSchema.partial();
/** Dto to update a `transferable */
export type TransferableUpdateDto = z.infer<typeof transferableUpdateDtoSchema>;
