import * as joy from "@mui/joy";

import { getAutocompleteListBoxStyle } from "./AutocompleteListBox";
import { filterProps } from "../../../../services/styled/filterProps";
import { textBody } from "../../../ui-layout/styles/textStyles";
import { theme } from "../../../ui-layout/styles/theme";

/** Props for styling {@link AutoComplete} */
interface AutocompleteStyleProps {
	/** Set to `true` to apply the cell styles */
	$applyCellStyles?: boolean;
	error?: boolean;
}
const AutocompleteStyled = joy.styled(
	joy.Autocomplete,
	filterProps,
)<AutocompleteStyleProps>`
	${textBody};
	background: ${theme.palette.white.primary};
	border: none;
	border-radius: 4px;
	box-shadow: none;
	color: ${theme.palette.blue.primary};
	height: 42px;
	min-height: 42px;
	outline: ${({ error }) => `1px solid ${error ? theme.palette.transactional.danger : theme.palette.grey[40]}`};
	padding-left: 5px;

	--Input-focusedThickness: 0;

	.${joy.autocompleteClasses.input}::placeholder {
		color: ${theme.vars.palette.grey[50]};
	}

	&.${joy.autocompleteClasses.disabled} {
		color: ${theme.vars.palette.grey[45]};
		cursor: not-allowed !important;
		pointer-events: all !important;
	}

	&.${joy.autocompleteClasses.disabled} .${joy.autocompleteClasses.input} {
		cursor: not-allowed !important;
		pointer-events: all !important;
	}

	&.${joy.autocompleteClasses.focused} {
		outline: 2px solid ${theme.palette.blue.primary};
	}
` as typeof joy.Autocomplete;

/**
 * Props for {@link AutoComplete}.
 * This is a extension of {@link joy.AutocompleteProps}.
 */
export interface AutocompleteProps<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined,
> extends joy.AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
		AutocompleteStyleProps {}
/** Override of {@link joy.Autocomplete} with default style */
export function AutoComplete<
	T,
	Multiple extends boolean | undefined = undefined,
	DisableClearable extends boolean | undefined = undefined,
	FreeSolo extends boolean | undefined = undefined,
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
	return (
		<AutocompleteStyled
			data-testid="ui/auto-complete"
			slotProps={{
				clearIndicator: { "data-testid": "ui/auto-complete/clear" },
				listbox: { sx: getAutocompleteListBoxStyle },
			}}
			{...props}
		/>
	);
}
