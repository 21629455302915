import * as z from "zod";

import { accountClosureModelSchema } from "../../../account/closure/account-closure.model";
import { accountClosureDtoSchema } from "../../../account/closure/dtos/account-closure.dto";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "account-closure";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkAccountClosure: accountClosureModelSchema.shape._id,
});
/**
 * Movement group for an {@link accountClosureDtoSchema}
 * When closing the account.
 *
 * NOTE: The values of this group are percent and not amount
 */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	accountClosure: z.lazy(() => accountClosureDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
