import styled from "@emotion/styled";
import * as joy from "@mui/joy";

import { textBody } from "../../../ui-layout/styles/textStyles";
import { theme } from "../../../ui-layout/styles/theme";

/**
 * The option wrapper to use in all components rendering {@link Select} options
 */
export const SelectOption = styled(joy.Option)`
	cursor: pointer !important;
	padding: 8px 8px 8px 12px;
	${textBody}

	:hover {
		background-color: ${theme.palette.grey[10]};
	}

	:not(:last-child) {
		border-bottom: 1px solid ${theme.palette.grey[40]};
	}
`;
