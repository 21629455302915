import { Mutation, Query, QueryKey } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { Router } from "next/router";

import { FetchError } from "./FetchError";
import { BannerVariants } from "../../../features/ui-atoms/components/Banner";
import { showToast } from "../../../features/ui-atoms/services/showToast";
import { ROUTES } from "../../routes";

// Temporary ...
interface ToastDisplay {
	message: string;
	title: string;
}

function getToastErrorDefault(t: TFunction): ToastDisplay {
	return {
		message: t("errors.http._default.message"),
		title: t("errors.http._default.title"),
	};
}
function getToastErrorDisplay(t: TFunction, status: number): ToastDisplay {
	switch (status) {
		case 400:
			return {
				message: t("errors.http.400.message"),
				title: t("errors.http.400.title"),
			};
		case 401:
			return {
				message: t("errors.http.401.message"),
				title: t("errors.http.401.title"),
			};
		case 403:
			return {
				message: t("errors.http.403.message"),
				title: t("errors.http.403.title"),
			};
	}

	return getToastErrorDefault(t);
}

export const handleGenericMutationErrors = (
	error: FetchError,
	variables: unknown,
	context: unknown,
	mutation: Mutation<unknown, unknown, unknown, unknown>,
	router: Router,
	t: TFunction<"translation", undefined>,
): void => {
	const { status } = error.response;

	if (status !== 401) {
		const { message, title } = getToastErrorDisplay(t, status);

		showToast({
			message:
				typeof mutation.meta?.errorMessage === "string"
					? mutation.meta.errorMessage
					: message,
			position: "top-right",
			title,
			variant: BannerVariants.ERROR,
		});
	}

	handleHTTPStatus(status, router);
};

export const handleGenericMutationSuccess = (
	_data: unknown,
	_variables: unknown,
	_context: unknown,
	_mutation: Mutation<unknown, unknown, unknown, unknown>,
	_router: Router,
	_t: TFunction<"translation", undefined>,
): void => {
	// We do nothing here for now.
};

export const handleGenericQueryErrors = (
	error: Error,
	query: Query<unknown, unknown, unknown, QueryKey>,
	router: Router,
	t: TFunction<"translation", undefined>,
): void => {
	if (!(error instanceof FetchError)) {
		showToast({
			...getToastErrorDefault(t),
			position: "top-right",
			variant: BannerVariants.ERROR,
		});
		return;
	}

	const { status } = error.response;

	if (status !== 401) {
		const { message, title } = getToastErrorDisplay(t, status);
		showToast({
			message:
				typeof query.meta?.errorMessage === "string"
					? query.meta.errorMessage
					: message,
			position: "top-right",
			title,
			variant: BannerVariants.ERROR,
		});
	}

	handleHTTPStatus(error.response.status, router);
};

export const handleHTTPStatus = (code: number, router: Router): void => {
	if (code === 401) {
		void router.push(ROUTES.auth.login.url().pathname);
	}
};
