import * as z from "zod";

import { transferableClosureDtoSchema } from "../../../transferable/closure/dtos/transferable-closure.dto";
import { transferableClosureModelSchema } from "../../../transferable/closure/transferable-closure.model";
import { createSchema } from "../lib";

/** Unique type discrimination for {@link Model} */
export const TYPE = "transferable-closure";

/** Validation schema for {@link Model} */
export const schema = createSchema(TYPE, {
	fkTransferableClosure: transferableClosureModelSchema.shape._id,
});
/** Movement Group representation of a {@link transferableClosureModelSchema} */
export type Model = z.infer<typeof schema>;

/** Validation schema for {@link Dto} */
export const dtoSchema = z.object({
	...schema.shape,
	transferableClosure: z.lazy(() => transferableClosureDtoSchema),
});
/**
 * Dto extension of {@link Model}.
 * Principally for query/filter purposes.
 */
export type Dto = z.infer<typeof dtoSchema>;
