import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { loanModelSchema } from "../loan.model";

/** Validation schema for {@link LoanEvolutionType} */
export const loanEvolutionTypeSchema = z.enum(["INCREASE", "REFUND"]);
export type LoanEvolutionType = z.infer<typeof loanEvolutionTypeSchema>;

/** Zod Shape for {@link loanEvolutionModelSchema} */
export const loanEvolutionModelRawShape = {
	amount: z.number().describe("Amount for this evolution (only for visual)"),
	period: EntryCommon.periodDateSchema(),
	type: loanEvolutionTypeSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link LoanModel} */
export const loanEvolutionModelSchema = z.object({
	...Model.schema.shape,
	...addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(loanEvolutionModelRawShape),
		{
			...loanEvolutionModelRawShape,
			fkLoan: loanModelSchema.shape._id,
		},
	),
});
export type LoanEvolutionModel = z.infer<typeof loanEvolutionModelSchema>;
